@use 'sass:math';

.hero-elements {
  --heading-text-wrap: balance;

  --grid-template-areas: 'big' 'top' 'bottom';

  --grid-column-1: 100%;
  --grid-column-2: 0;

  --grid-template-columns: var(--grid-column-1);
  --grid-template-rows: repeat(3, auto);

  --grid-gap: 0;

  --heading-1-size: #{px-to-rem(26px)};
  --heading-1-ico-mb: 0;
  --bs-body-font-size: #{px-to-rem(18px)};

  --heading-2-size: #{px-to-rem(20px)};
  --heading-2-mb: 0;

  --img-width: auto;
  --img-height: auto;
  --img-overlay: none;

  --img-overlay-gradient: #{linear-gradient(
      0deg,
      rgba(var(--bs-teaser-rgb), 1) 0%,
      rgba(var(--bs-teaser-rgb), 0.6) 50%,
      rgba(var(--bs-teaser-rgb), 0) 80%
    )};

  @include media-breakpoint-up(md) {
    --grid-template-areas: 'big big' 'top bottom';

    --grid-column-1: #{calc(percentage(math.div(6, 12)) - $grid-gutter-width * 0.25)};
    --grid-column-2: #{calc(percentage(math.div(6, 12)) - $grid-gutter-width * 0.25)};

    --grid-template-rows: auto 1fr;

    --grid-gap: #{$grid-gutter-width * 0.5};

    --heading-1-size: #{px-to-rem(36px)};
    --bs-body-font-size: #{px-to-rem(20px)};

    --heading-2-size: #{px-to-rem(24px)};

    --img-overlay-gradient: #{linear-gradient(
        0deg,
        rgba(var(--bs-teaser-rgb), 0.6) 0%,
        rgba(var(--bs-teaser-rgb), 0.3) 50%,
        rgba(var(--bs-teaser-rgb), 0) 80%
      )};
  }

  @include media-breakpoint-up(xl) {
    --grid-template-areas: 'big top' 'big bottom';

    --grid-column-1: #{calc(percentage(math.div(8, 12)) - $grid-gutter-width * 0.5)};
    --grid-column-2: 1fr;

    --heading-1-size: #{px-to-rem(52px)};
    --bs-body-font-size: #{px-to-rem(22px)};

    --heading-2-size: #{px-to-rem(28px)};
  }

  display: grid;
  grid-template-areas: var(--grid-template-areas);
  grid-template-columns: var(--grid-template-columns);
  grid-template-rows: var(--grid-template-rows);
  gap: var(--grid-gap);

  picture {
    position: relative;
    overflow: hidden;

    img {
      object-fit: cover;
      height: var(--img-height);
      width: var(--img-width);
    }

    &:after {
      content: '';
      display: var(--img-overlay);
      position: absolute;
      inset: 0;
      background: var(--img-overlay-gradient);
    }
  }

  .frame {
    &-type-theme_hero_big {
      --img-width: 100%;
      --img-height: 100%;
      --img-overlay: block;

      grid-area: big;

      @include media-breakpoint-up(xl) {
        --img-overlay-gradient: #{linear-gradient(
            0deg,
            rgba(var(--bs-teaser-rgb), 0.6) 0%,
            rgba(var(--bs-teaser-rgb), 0.4) 60%,
            rgba(var(--bs-teaser-rgb), 0) 100%
          )};
      }

      a {
        margin-left: var(--heading-1-ico-pl);
      }
    }

    &-type-theme_hero_small {
      --one-column-grid-cols: #{px-to-rem(80px)} 1fr;
      --one-column-grid-layer-1-col: 2;
      --one-column-grid-gap: #{$grid-gutter-width * 0.75};

      a {
        text-decoration: underline;
        text-underline-offset: var(--underline-offset);
        text-decoration-thickness: var(--underline-thickness);

        @include motion-safe {
          transition: all 0.2s ease-in-out;
        }
      }

      @include media-breakpoint-up(md) {
        --one-column-grid-cols: 1fr;
        --one-column-grid-layer-1-col: 1;
        --one-column-grid-gap: 0;

        --img-width: 100%;
        --img-height: 100%;
        --img-overlay: block;
      }

      &-31 {
        grid-area: top;
      }

      &-32 {
        grid-area: bottom;
        position: relative;

        &:before {
          content: '';
          height: 1px;
          background: map-get($theme-colors, 'dark');
          position: absolute;
          top: 0;
          left: $grid-gutter-width * 0.75;
          right: $grid-gutter-width * 0.75;
        }
      }

      img {
        @include motion-safe {
          transition: all 0.2s ease-in-out;
          transform: scale(var(--hover-scale, 1)) rotate(var(--hover-rotate, 0deg));
        }
      }

      &:hover {
        --underline-thickness: 2px;
        --underline-offset: 7px;
        --hover-scale: 1.02;
        --hover-rotate: -0.5deg;
      }
    }
  }

  .ico-plus {
    --heading-1-color: #{map-get($theme-colors, 'white')};
    --heading-1-ico-pl: #{px-to-rem(18.5px)};
    --heading-1-ico-pt: #{px-to-rem(14px)};

    @include media-breakpoint-up(md) {
      --heading-1-ico-pl: #{px-to-rem(18px)};
      --heading-1-ico-pt: #{px-to-rem(11px)};
    }

    @include media-breakpoint-up(xl) {
      --heading-1-ico-pl: #{px-to-rem(32px)};
      --heading-1-ico-pt: #{px-to-rem(21px)};
    }
  }

  p {
    padding-left: var(--heading-1-ico-pl);
    font-size: var(--bs-body-font-size);
  }
}
