// use this, if you need to breakout the bootstrap container
// this breakout row allows you to add a div inside a container which uses
// 100% of the viewport even if its inside a .container class with limited width
.break-out-row {
  width: 100vw;
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);

  &__container {
    max-width: var(--container-max-width);
    margin: 0 auto;

    [class*='col'] {
      padding-inline: #{$grid-gutter-width * 0.5};
    }
  }
}
