@use 'sass:math';

.slider {
  &-wrapper {
    --slide-width: 244px;
    --flex-item: 0 0 var(--slide-width);
    --bs-gutter-x: 2rem;
    --full-width-grid-margin: calc(var(--bs-gutter-x) * 0.5);
    --track-column: 2 / 3;
    --flex-wrap: nowrap;
    --gap-column: #{$grid-gutter-width * 0.75};
    --gap: #{$grid-gutter-width * 0.75} var(--gap-column);
    --template-rows: auto;
    --slides-xl-count: 4;
    --slides-xl-gaps: 3;

    &.slides-xl-3 {
      --slides-xl-count: 3;
      --slides-xl-gaps: 2;
    }

    grid-template-columns: minmax(var(--full-width-grid-margin), 1fr) minmax(0, var(--container-max-width)) minmax(
        var(--full-width-grid-margin),
        1fr
      );
    grid-template-rows: var(--template-rows);
    z-index: 1;
    align-items: center;

    @include media-breakpoint-up(sm) {
      --slide-width: 220px;
    }

    @include media-breakpoint-up(md) {
      --slide-width: 244px;
    }

    @include media-breakpoint-up(md) {
      --gap-column: #{$grid-gutter-width * 0.75};
    }

    @include media-breakpoint-up(xl) {
      --slide-width: calc((100% - (var(--slides-xl-gaps) * (var(--gap-column)))) / var(--slides-xl-count));
      --flex-item: 0 1 var(--slide-width);
      --track-column: 2;
      --flex-wrap: wrap;

      &.slides-desktop-slide {
        --flex-wrap: nowrap;
        --flex-item: 0 0 var(--slide-width);
      }
    }
  }

  &-track {
    grid-column: var(--track-column);
    grid-row: 1;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: none;

    @include media-breakpoint-up(xl) {
      scrollbar-width: none;
    }

    @include media-breakpoint-up(ultrawide) {
      max-width: percentage(math.div(10, 12));
    }

    &__inner {
      flex-wrap: var(--flex-wrap);
      gap: var(--gap);
    }
  }

  &-item {
    flex: var(--flex-item);
    scroll-snap-align: center;

    .card.card-products .card-body {
      color: __get($theme-colors, 'dark');
    }

    .gsplidebox {
      &:before {
        content: attr(data-description);
        position: absolute;
        bottom: 0;
        background: rgba(__get($theme-colors, 'white'), 0.8);
        backdrop-filter: blur(2px);
        color: __get($theme-colors, 'dark');
        font-size: $slider-description-font-size;
        padding: $grid-gutter-width * 0.125 $grid-gutter-width * 0.25;
        width: 100%;
      }

      &[data-description='']:before {
        content: none;
        padding: 0;
        background: none;
      }
    }
  }

  &-control {
    grid-row: 2;
    grid-column: 2;
    z-index: 2;
    width: fit-content;
    height: fit-content;
    opacity: 1;
    line-height: 1;
    padding: $grid-gutter-width * 0.75 $grid-gutter-width * 0.375;
    margin: 0;
    border-color: __get($theme-colors, 'white');
    background-color: __get($theme-colors, 'white');
    display: none;

    &[disabled] {
      opacity: 0;
    }

    &.hidden {
      display: none;
    }

    &:hover,
    &:focus {
      background-color: __get($theme-colors, 'white');
      border-color: __get($theme-colors, 'white');
    }

    .is-scrolling & {
      pointer-events: none;
    }

    @include media-breakpoint-up(xl) {
      display: block;
    }

    &__prev {
      justify-self: start;

      svg {
        rotate: 90deg;
      }
    }

    &__next {
      justify-self: end;

      svg {
        rotate: -90deg;
      }
    }
  }

  &-dots {
    grid-column: 2 / 3;
    grid-row: 2;
    display: none;

    &.hidden {
      display: none;
    }

    @include media-breakpoint-up(xl) {
      display: flex;
    }

    &__item {
      button {
        height: 8px;
        width: 24px;
        border: none;
        background-color: __get($theme-colors, 'medium');

        &.active {
          background-color: __get($theme-colors, 'dark');
          width: 48px;
          pointer-events: none;
        }
      }
    }
  }
}
