body {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-normal;
  margin-top: var(--page-header-height);
  overflow-x: hidden;

  &.belayout-blank {
    margin-top: 0;
  }

  &:has(.sticky-nav__overlay:not([hidden])) {
    overflow-y: hidden;
  }

  &:has(.main-nav__subnav-toggle[aria-expanded='true']) {
    overflow-y: hidden;
  }
}
