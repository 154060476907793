// LEGACY
.indicator {
  &-list {
    &__item {
      figure {
        transition: transform 0.2s ease-in-out;
      }

      figcaption {
        font-size: inherit;
      }

      svg {
        height: 85px;
        width: 85px;
      }

      &-text {
        background: map-get($theme-colors, 'primary');
        bottom: 0;
        color: map-get($theme-colors, 'white');
        font-weight: $font-weight-bold;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(100%);
        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

        a:hover &,
        a:focus-visible & {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }

    &__item {
      a:hover,
      a:focus-visible {
        figure {
          transform: translateY(-100%);
        }
      }
    }
  }
}
