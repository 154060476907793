// hardcoded style for the h1 and h2 on the startpage
// but only on epluse.com - not on the landingpages
// see https://support.sup7.at/issues/15463
.rootpage:not(.is_landingpage) {
  .frame:first-child {
    h2 {
      --heading-2-mb: #{px-to-rem(__get($heading-sizes, 3 'xs' 'margin'))};
    }
  }

  @include media-breakpoint-up(xl) {
    h1 {
      //font-size: px-to-rem(__get($heading-sizes, 2 'xl' 'size'));
      //margin-bottom: px-to-rem(__get($heading-sizes, 2 'xl' 'margin'));

      &.ico-plus {
        //padding-top: px-to-rem(__get($plus-icon, 'sizes' 2 'xl' 'top'));
        //padding-left: px-to-rem(__get($plus-icon, 'sizes' 2 'xl' 'left'));
        //margin-bottom: px-to-rem(__get($plus-icon, 'sizes' 2 'xl' 'margin'));

        &:before {
          //width: px-to-rem(__get($plus-icon, 'sizes' 2 'xl' 'w'));
          //height: px-to-rem(__get($plus-icon, 'sizes' 2 'xl' 'h'));
        }
      }
    }

    .frame:first-child {
      h2 {
        --heading-2-size: #{px-to-rem(__get($heading-sizes, 3 'xl' 'size'))};
        --heading-2-mb: #{px-to-rem(__get($heading-sizes, 3 'xl' 'margin'))};

        //font-size: px-to-rem(__get($heading-sizes, 3 'xl' 'size'));
        //margin-bottom: px-to-rem(__get($heading-sizes, 3 'xl' 'margin'));
      }
    }

    .frame-type-header {
      --heading-1-size: #{px-to-rem(__get($heading-sizes, 2 'xl' 'size'))};

      --heading-1-ico-pt: #{px-to-rem(__get($plus-icon, 'sizes' 2 'xl' 'top'))};
      --heading-1-ico-pl: #{px-to-rem(__get($plus-icon, 'sizes' 2 'xl' 'left'))};
      --heading-1-ico-w: #{px-to-rem(__get($plus-icon, 'sizes' 2 'xl' 'w'))};
      --heading-1-ico-h: #{px-to-rem(__get($plus-icon, 'sizes' 2 'xl' 'h'))};
      --heading-1-mb: #{px-to-rem(__get($plus-icon, 'sizes' 2 'xl' 'margin'))};
    }
  }
}
