@import '../../../node_modules/glightbox/dist/css/glightbox';

.goverlay,
.glightbox-mobile .goverlay {
  background: rgba($white, 0.95);
}

.glightbox-clean .gslide-media {
  box-shadow: $box-shadow;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  background: map-get($theme-colors, 'white');
}

.glightbox-clean .gslide-description {
  box-shadow: $box-shadow;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

#glightbox-slider {
  @include media-breakpoint-down(md) {
    .gslide-media,
    .gslide-description {
      box-shadow: none;
    }

    .gslide-description {
      padding: 0;
      background: #fff;

      * {
        color: $body-color;
      }

      a {
        color: $link-color;
      }
    }

    .gdesc-inner {
      padding: $grid-gutter-width * 0.5;
      box-shadow: none;
    }

    .gslide-title {
      margin-bottom: $grid-gutter-width * 0.25;
    }
  }
}
