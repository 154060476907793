// microsoft dynamics form integration, style overrides to visually be close to what we do with bootstrap.
#mscrmform {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-normal;

  .formLoader {
    border-top: 16px solid $primary;
  }

  /** ¯\_(ツ)_/¯ */
  table {
    display: table !important;
  }

  form {
    font-family: $font-family-sans-serif;

    .twoOptionFormFieldBlock div.radiobuttons,
    .twoOptionFormFieldBlock div.twooption_checkbox,
    .optionSetFormFieldBlock div.radiobuttons,
    .multiOptionSetFormFieldBlock fieldset,
    .textFormFieldBlock label,
    .dateTimeFormFieldBlock label,
    .lookupFormFieldBlock label,
    .twoOptionFormFieldBlock label.block-label,
    .optionSetFormFieldBlock label.block-label,
    .multiOptionSetFormFieldBlock label.block-label,
    div[data-editorblocktype='Captcha'] label[id^='wlspispHipInstructionContainer'],
    .textFormFieldBlock label *,
    .dateTimeFormFieldBlock label *,
    .lookupFormFieldBlock label *,
    .twoOptionFormFieldBlock label.block-label *,
    .optionSetFormFieldBlock label.block-label *,
    .multiOptionSetFormFieldBlock label.block-label *,
    .consentBlock label p,
    .phoneFormFieldBlock label,
    .marketingForm .primaryButton,
    .submitButton {
      font-family: $font-family-sans-serif;
    }

    .outer {
      width: 100% !important;
    }

    [data-layout='true'] {
      margin: 0 !important;
      max-width: none !important;
    }

    // 'normal' labels
    label.block-label,
    label {
      display: inline-block;
      font-size: 0.875rem;
      font-weight: $font-weight-normal;
      margin-bottom: 0.25rem;
    }

    // some labels are set to bold and bigger then regular labels
    .textFormFieldBlock label,
    .dateTimeFormFieldBlock label,
    .lookupFormFieldBlock label,
    .twoOptionFormFieldBlock label.block-label,
    .optionSetFormFieldBlock label.block-label,
    .multiOptionSetFormFieldBlock label.block-label,
    div[data-editorblocktype='Captcha'] label[id^='wlspispHipInstructionContainer'],
    .textFormFieldBlock label *,
    .dateTimeFormFieldBlock label *,
    .lookupFormFieldBlock label *,
    .twoOptionFormFieldBlock label.block-label *,
    .optionSetFormFieldBlock label.block-label *,
    .multiOptionSetFormFieldBlock label.block-label * {
      font-size: 0.875rem;
      font-weight: $font-weight-normal;
    }

    fieldset {
      margin-top: 0;
      margin-bottom: $grid-gutter-width * 2;
    }

    input {
      accent-color: $primary;
      appearance: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid $gray-400;
      border-radius: 2px;
      color: $gray-700;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 0.71875rem 1rem;
      transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    }

    select {
      display: block;
      width: 100%;
      padding: 0.71875rem 3rem 0.71875rem 1rem;
      -moz-padding-start: calc(1rem - 3px);
      //font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.5;
      color: $gray-700;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%233a3a3a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 16px 12px;
      border: 1px solid $gray-400;
      border-radius: 2px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      appearance: none;
    }

    textarea {
      padding: 0.71875rem 1rem;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.5;
      color: $gray-700;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid $gray-400;
      appearance: none;
      border-radius: 2px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    }

    table.multi .textFormFieldBlock,
    table.multi .dateTimeFormFieldBlock,
    table.multi .twoOptionFormFieldBlock,
    table.multi .optionSetFormFieldBlock,
    table.multi .multiOptionSetFormFieldBlock,
    table.multi .lookupFormFieldBlock,
    table.multi .phoneFormFieldBlock {
      padding: 0;
    }

    // labels for radio and checkbox blocks
    .multiOptionSetFormFieldBlock > label,
    .twoOptionFormFieldBlock > label {
      font-weight: $font-weight-bold;
      margin-bottom: 16px;
    }

    // multicolumn radio and checkbox blocks
    .multiOptionSetFormFieldBlock > fieldset {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      > div {
        box-sizing: border-box;
        flex-basis: 49%;

        @media (max-width: 480px) {
          flex-basis: 100%;
        }
      }
    }

    // phone fields neads separate treatment
    .phoneFormFieldBlock .phoneCountryCode {
      border: none;
    }

    // layout default field wrappers woth consistent margins
    .textFormFieldBlock,
    .dateTimeFormFieldBlock,
    .twoOptionFormFieldBlock,
    .optionSetFormFieldBlock,
    .multiOptionSetFormFieldBlock,
    .lookupFormFieldBlock,
    .phoneFormFieldBlock {
      padding: 0px 0px;
      margin-bottom: 1rem !important;
      display: flex;
      flex-direction: column;
      gap: 0px;
    }

    // remove ms-styling from dual-column fields, but add padding to first column
    th.columnContainer {
      &.inner {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }

      th.inner {
        padding: 0 !important;
      }

      & + .columnContainer + .columnContainer {
        padding-left: 0.5rem;
      }
    }

    .columns-equal-class.wrap-section {
      table.containerWrapper > tbody > tr > th:first-child {
        padding-right: 0.5rem !important;
      }

      table.containerWrapper > tbody > tr > th:only-child {
        padding-right: 0 !important;
      }
    }

    // don't center submit button, right align
    .marketingForm .primaryButtonWrapper,
    .submitButtonWrapper {
      padding: 0;
      text-align: end;
    }

    // focus state of inputs
    input,
    select,
    textarea {
      &:focus {
        color: $gray-700;
        background-color: $white;
        border-color: $success;
        box-shadow: 0 0 0 0.25rem rgba($primary, 0.25);
      }

      &:focus-visible {
        outline: $gray-700 dashed 2px;
        outline-offset: 2px;
      }

      &::placeholder,
      &:invalid {
        color: $gray-600;
        opacity: 1;
      }
    }
  }

  div[data-cached-form-url] .onFormSubmittedFeedback {
    width: auto !important;
    height: auto !important;
    display: block !important;
    margin: 0 !important;
    background: none !important;

    & > div {
      padding: 0 !important;
      display: block !important;
      margin-bottom: 1rem !important;
      text-align: left !important;

      & > div {
        padding: 0 !important;
        margin-bottom: 1rem !important;
        display: block !important;
        text-align: left !important;
      }
    }

    .onFormSubmittedFeedbackIcon {
      display: none !important;
    }

    div[data-submissionresponse='success'] {
      .onFormSubmittedFeedbackMessage {
        color: var(--brand-color2) !important;
        font-weight: $font-weight-bold;
      }
    }

    div[data-submissionresponse='error'] {
      .onFormSubmittedFeedbackMessage {
        padding: $grid-gutter-width * 0.25 !important;
        font-weight: $font-weight-bold;
        border: 2px solid $danger;
        color: $danger !important;
      }
    }

    * {
      font-family: $font-family-sans-serif !important;
      font-size: var(--bs-body-font-size) !important;
      line-height: var(--bs-body-line-height) !important;
      color: var(--bs-body-color) !important;
    }

    a {
      color: var(--brand-color2) !important;
    }
  }

  // h1 section headlines
  h1 {
    color: var(--bs-body-color);
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
  }

  div[data-editorblocktype] {
    margin-left: 0 !important;
  }

  .emptyContainer > table.outer > tbody > tr > th {
    padding-left: 0 !important;
  }
}
