@use 'sass:math';

#chat-widget-container {
  left: 50% !important;
  transform: translateX(-50%) !important;
  bottom: $grid-gutter-width * 1.5 !important;

  @include media-breakpoint-up(md) {
    bottom: 0 !important;
    left: unset !important;
    transform: none !important;
    right: $grid-gutter-width * 1.5 !important;
  }
}

.sticky-nav {
  &__list {
    margin: 0;
    list-style: none;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding: px-to-rem(2px);
    gap: px-to-rem(2px);
    background: $white;
    box-shadow: $box-shadow;
    z-index: 800;

    @include media-breakpoint-up(md) {
      left: unset;
      bottom: unset;
      top: 50%;
      right: 0;
      flex-direction: column;
      transform: translateY(-50%);
    }
  }

  &__toggle-button {
    display: block;
    aspect-ratio: 1 / 1;
    padding: 0;
    margin: 0;
    background: $primary;
    font: inherit;
    text-decoration: none;
    color: $white !important;
    border: none;
    outline-offset: -4px !important;

    &--footer {
      position: fixed;
      bottom: 0;
      height: 50px;
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      white-space: nowrap;
      width: 255px;
      aspect-ratio: auto;
      border-top-right-radius: 7px;
      border-top-left-radius: 7px;
      text-align: left;
      padding: 0 14px;
      font-size: 15px;
      font-weight: bold;
      z-index: 2;

      @include media-breakpoint-up(md) {
        right: 61px;
        left: auto;
        transform: translate(0);
      }
    }
  }

  &__popup {
    position: fixed;
    z-index: 9999;
    background: $white;
    margin-bottom: 1.35rem;
    right: math.div($grid-gutter-width, 2);
    left: math.div($grid-gutter-width, 2);
    bottom: $grid-gutter-width * 1.5 + px-to-rem(4px);
    box-shadow: $box-shadow;
    padding: math.div($grid-gutter-width, 2);

    @include focus-ring;

    @include media-breakpoint-up(md) {
      margin-right: 1.35rem;
      left: unset;
      bottom: unset;
      margin-bottom: unset;
      right: $grid-gutter-width * 1.5 + px-to-rem(4px);
      top: var(--popup-toggle-y, 50%);
      transform: translateY(-50%);

      &#consent_chat {
        transform: translateY(calc(-100% + #{$grid-gutter-width * -2}));

        &:after {
          top: auto;
          left: 50%;
          bottom: -14px;
          transform: translateX(-50%) rotate(135deg);
        }
      }
    }

    &::after {
      display: block;
      position: absolute;
      content: '';
      width: $grid-gutter-width * 0.875;
      aspect-ratio: 1 / 1;
      left: calc(var(--popup-toggle-x, 50%) - #{math.div($grid-gutter-width, 2)});
      top: 100%;
      transform: translate(-50%, -50%) rotateZ(45deg);
      transform-origin: center center;
      background: white;
      z-index: -1;
      clip-path: polygon(-10% 110%, 110% 110%, 110% -10%);
      box-shadow: $box-shadow;

      @include media-breakpoint-up(md) {
        top: 50%;
        left: 100%;
        clip-path: polygon(-10% -10%, 110% 110%, 100% -10%);
      }
    }

    &--dialog {
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      max-height: 100dvh;
      margin-bottom: 0;
      overflow-y: auto;
      overscroll-behavior: contain;

      &::after {
        display: none;
      }

      strong.h1 {
        margin-bottom: $grid-gutter-width;
      }

      @include media-breakpoint-up(md) {
        bottom: unset;
        right: unset;
        top: 50%;
        left: 50%;
        width: 100%;
        max-height: min(px-to-rem(735px), calc(100vh - #{$grid-gutter-width * 2}));
        max-width: px-to-rem(500px);
        transform: translate(-50%, -50%);

        strong.h1 {
          margin-bottom: $grid-gutter-width * 1.5;
        }
      }

      @include media-breakpoint-up(xl) {
        max-height: 80vh;
        max-width: px-to-rem(800px);
      }

      @include media-breakpoint-up(ultrawide) {
        max-width: px-to-rem(1140px);
      }
    }
  }

  &__popup h2 {
    font-size: px-to-rem(24px);
    color: $body-color;
    margin: 0;

    &::after {
      display: block;
      content: '';
      max-width: $grid-gutter-width * 2;
      height: px-to-rem(2px);
      background: $primary;
      margin: math.div($grid-gutter-width, 2) 0;
    }
  }

  &__overlay {
    display: block;
    position: fixed;
    content: '';
    inset: 0 0 0 0;
    background: fade-out($white, 0.2);
    z-index: 9997;
  }

  &__close {
    display: block;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    width: px-to-rem(22px);
    aspect-ratio: 1 / 1;
  }
}
