.tx-solr-searchbox.tx-solr-suggest,
.tx-solr-search-form .tx-solr-search-form {
  --custom-marker-display: none;
  position: relative;

  .tx-solr-suggest-box {
    position: absolute;
    left: 0;
    top: var(--top-search-height);
    width: 100%;
    border: 1px solid $gray-300;
    box-shadow: $box-shadow;
    background: __get($theme-colors, 'white');
    z-index: 10;

    @include media-breakpoint-up(xl) {
      left: calc(var(--top-search-input-width) - (var(--top-search-width) * 3));
      width: 300%;

      main & {
        --top-search-height: 75px;
        left: 0;
        width: 100%;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        a {
          background: $body-bg;
          padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;
          display: block;

          &.hover {
            background: __get($theme-colors, 'primary');
            color: __get($theme-colors, 'white');
          }
        }
      }

      &:last-child {
        border-top: 2px solid $gray-300;
      }
    }
  }
}
