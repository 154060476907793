@use 'sass:math';

$supi-grid-gutter-width: 32px;
$supi-breakpoint: map-get($grid-breakpoints, 'md');

$supi-colors: (
  primary: $body-color,
  success: $primary,
  warning: $secondary,
  notice: $primary,
  button-wrap-bgcolor: $gray-100,
);

@import '../../../../../../vendor/supseven/supi/Build/Src/Scss/Supi';

:root {
  --supi-color-dismiss: #{$body-bg};
  --supi-color-dismiss-background: #{$primary};
  --supi-font-size-head: #{px-to-rem(20px)};
  --supi-font-size: #{px-to-rem(13px)};

  @include media-breakpoint-up(md) {
    --supi-font-size: #{px-to-rem(16px)};
    --supi-font-size-head: #{px-to-rem(24px)};
  }

  --supi-banner-box-shadow: #{$box-shadow-xl};
  --supi-color-choose-invert: #{$white};
}

.tx-supi {
  &.theme-default {
    margin: math.div($grid-gutter-width, 2);
    width: auto;
    left: 0;
    right: 0;
    border-top: none;

    @include focus-ring;

    @include media-breakpoint-up(md) {
      left: unset;
      right: unset;
      border: none;
      max-width: 500px;
      width: 100%;
    }

    @include media-breakpoint-up(xl) {
      max-width: 800px;
    }

    @include media-breakpoint-up(ultrawide) {
      max-width: 1140px;
    }

    .tx-supi__buttongroup {
      ul {
        gap: $supi-grid-gutter-width * 0.25;

        @include media-breakpoint-up(md) {
          flex-direction: column;
          gap: $supi-grid-gutter-width * 0.5;
        }

        @include media-breakpoint-up(xl) {
          flex-direction: row;
        }

        li + li {
          margin-top: 0;
        }
      }

      button {
        padding-block: $supi-grid-gutter-width * 0.25;
        font-weight: 700;

        @include media-breakpoint-up(md) {
          padding-block: $supi-grid-gutter-width * 0.5;
        }
      }
    }

    #supi__description p:not(:last-child) {
      margin-bottom: $supi-grid-gutter-width * 0.25;
    }
  }

  &__youtube {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    border: 1px solid $body-color;

    .frame-type-list p + & {
      margin-top: $grid-gutter-width * 0.5;

      @include media-breakpoint-up(md) {
        margin-top: $grid-gutter-width;
      }

      @include media-breakpoint-up(xxl) {
        margin-top: $grid-gutter-width * 2;
      }
    }

    @include media-breakpoint-up(md) {
      border: none;
    }

    @include media-breakpoint-up(lg) {
      .frame-imageorient-25 &,
      .frame-imageorient-26 & {
        border: 1px solid $body-color;
      }
    }

    @include media-breakpoint-up(xl) {
      .frame-imageorient-25 &,
      .frame-imageorient-26 & {
        border: none;
      }

      .frame-imageorient-25.frame-layout-7 &,
      .frame-imageorient-26.frame-layout-7 & {
        border: 1px solid $body-color;
      }
    }

    figure,
    &-text {
      grid-column: 1;
    }

    figure {
      grid-row: 1;
    }

    &-text {
      grid-row: 2;
      top: auto;
      margin: 0;
      padding: 0;
      background: transparent;
      position: relative;
      display: grid;
      place-content: center;

      & > div {
        background: rgba($white, 0.9);
        padding: $grid-gutter-width * 0.5;

        @include media-breakpoint-up(lg) {
          .frame-imageorient-0 &,
          .frame-imageorient-8 &,
          .frame-type-list & {
            padding: $grid-gutter-width;
          }
        }
      }

      @include media-breakpoint-up(md) {
        grid-row: 1;
      }

      @include media-breakpoint-up(lg) {
        .frame-imageorient-25 &,
        .frame-imageorient-26 & {
          grid-row: 2;

          p {
            font-size: 0.875rem;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        .frame-imageorient-25 &,
        .frame-imageorient-26 & {
          grid-row: 1;

          p {
            font-size: 1rem;
          }
        }

        .frame-imageorient-25.frame-layout-7 &,
        .frame-imageorient-26.frame-layout-7 & {
          grid-row: 2;
        }
      }
    }
  }

  .sr-only {
    @include visually-hidden;
  }

  h2,
  .h2 {
    color: $body-color;
  }

  h3,
  .h3 {
    font-weight: 700;
  }

  &__block {
    margin-bottom: $grid-gutter-width;

    ul {
      margin-bottom: 0;
    }
  }

  &__label {
    margin-bottom: 0;
  }

  &__block-header {
    padding: math.div($grid-gutter-width, 4) 0;
  }

  &__block-body,
  &__block-item {
    margin: math.div($grid-gutter-width, 4) 0;
  }

  &__block-item__table {
    font-size: px-to-rem(14px);
  }

  .supi-checkbox {
    input:disabled + label {
      opacity: 1;
    }
  }
}

.supi-service {
  background: $gray-100;
  padding: math.div($grid-gutter-width, 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: math.div($grid-gutter-width, 2);
  margin-bottom: $grid-gutter-width * 2;
}
