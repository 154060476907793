@use 'sass:math';

.main-nav-sidebar {
  &__title {
    @extend h6;

    display: block;
    padding: math.div($grid-gutter-width, 4) 0;
    color: $primary;
    margin-bottom: math.div($grid-gutter-width, 4);
  }
}
