@use 'sass:math';

:root {
  --nav-width: 100%;
  --nav-link-color: #{$body-color};
  --nav-link-color-hover: currentColor;
  --nav-link-background-color-hover: #{$white};
  --nav-background-color: #{$white};

  @include media-breakpoint-up(xl) {
    --nav-title-width: 30%;
  }
}

.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $body-bg;
  z-index: $z-index-nav-overlay;
  padding-top: $grid-gutter-width * 1.5;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    overflow-y: scroll;
  }

  @include media-breakpoint-up(xl) {
    position: static;
    top: unset;
    left: unset;
    padding: 0;
    width: auto;
    height: auto;
    background: none;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  &__itemlist {
    margin: math.div($grid-gutter-width, 2) 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(xl) {
      margin: 0;
    }

    &--level-0 {
      @include media-breakpoint-up(xl) {
        display: flex;
      }
    }

    &--level-1 {
      @include media-breakpoint-up(xl) {
        width: 30%;
      }
    }

    header:not(.no-outside-nav) &.main-nav__itemlist-highlight {
      display: none;

      @include media-breakpoint-up(xl) {
        display: initial;
      }
    }
  }

  &__item {
    border-bottom: 1px solid $gray-300;
    position: static;
    background: var(--nav-background-color);

    @include media-breakpoint-up(xl) {
      border-bottom: none;
    }

    &--current a {
      .root-901 & {
        color: __get($theme-colors, primary);
        text-decoration-line: underline;
        text-underline-offset: var(--underline-offset);
      }
    }

    &--highlight {
      @include media-breakpoint-up(xl) {
        --nav-background-color: #{$primary};
        --nav-link-color: #{$white};
        --nav-link-color-hover: #{$white};
        --nav-link-background-color-hover: #{$brand-800};
        border-radius: $btn-border-radius;
      }
    }
  }

  &__title {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
    display: block;
    padding: math.div($grid-gutter-width, 4) $container-padding-x;

    &--level-0 {
      width: var(--nav-title-width);
    }

    &--level-1 {
      @include media-breakpoint-up(xl) {
        color: transparent !important;
        background: $body-bg !important;
        text-decoration: none !important;
      }
    }
  }

  &__overview {
    --nav-width: var(--nav-title-width);

    .main-nav__link--level-0 {
      font-weight: $font-weight-normal;
    }
  }

  &__link-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: var(--nav-width);

    &:not(&--level-0) {
      &:hover,
      &[aria-expanded='true'] {
        @include media-breakpoint-up(xl) {
          background: $gray-100;
          text-decoration: underline;
          text-underline-offset: var(--underline-offset);
        }
      }
    }

    &--active {
      @include media-breakpoint-up(xl) {
        background: $gray-100;
        text-decoration: underline;

        & ~ .main-nav__sidebar--product,
        & ~ .main-nav__sidebar--latest-exhibition,
        & ~ .main-nav__sidebar--latest-blog,
        & ~ .main-nav__sidebar--latest-press {
          display: block;
        }
      }
    }
  }

  &__link {
    display: block;
    flex-grow: 1;
    text-decoration: inherit;
    padding: math.div($grid-gutter-width, 4) $container-padding-x;
    color: var(--nav-link-color);
    outline-offset: -4px !important;
    @include transition($btn-transition);

    // related: https://support.sup7.at/issues/16112
    @include media-breakpoint-only(xl) {
      padding-left: 0.875rem;
      padding-right: 0.875rem;

      /** :has() selector will not work in firefox at the moment **/
      .main-nav__itemlist--level-0:has(.main-nav__item--level-0:nth-child(n + 7)) & {
        padding-left: 0.875rem;
        padding-right: 0.875rem;
      }
    }

    &:focus,
    &:active,
    &:hover {
      color: var(--nav-link-color-hover);
      background: var(--nav-link-background-color-hover);
    }

    &--level-0 {
      @include media-breakpoint-up(xl) {
        font-weight: $font-weight-bold;
      }
    }
  }

  &__subnav-toggle {
    display: block;
    border: none;
    font: inherit;
    background: none;
    padding: math.div($grid-gutter-width, 4) $container-padding-x;
    outline-offset: -4px !important;
    color: inherit;

    // related: https://support.sup7.at/issues/16112
    @include media-breakpoint-only(xl) {
      padding-left: 0.875rem;
      padding-right: 0.875rem;

      /** :has() selector will not work in firefox at the moment **/
      .main-nav__itemlist--level-0:has(.main-nav__item--level-0:nth-child(n + 7)) & {
        padding-left: 0.875rem;
        padding-right: 0.875rem;
      }
    }

    svg {
      display: block;
      width: math.div($grid-gutter-width, 8) * 3;
      height: math.div($grid-gutter-width, 8) * 3;
    }

    path {
      stroke: currentColor;
      stroke-width: 2px;
    }

    &--level-0 {
      @include media-breakpoint-up(xl) {
        font-weight: $font-weight-bold;

        svg {
          display: none;
        }

        &[aria-expanded='true'] {
          color: $brand-700;
          position: relative;

          &::after {
            position: absolute;
            display: block;
            content: '';
            top: calc(102% + #{($grid-gutter-width * 0.75 * (1 - math.sin(45deg))) + ($grid-gutter-width * 0.75)});
            left: 50%;
            width: $grid-gutter-width * 1.5 * math.cos(45deg);
            height: $grid-gutter-width * 1.5 * math.sin(45deg);
            transform: translateX(-50%) rotateZ(45deg);
            transform-origin: center center;
            z-index: $z-index-page-header;
            box-shadow: px-to-rem(1px) px-to-rem(1px) px-to-rem(4px) rgba($body-color, 0.25);
            clip-path: polygon(-10% 110%, -10% -10%, 110% -10%);
            background: $body-bg;
          }
        }
      }
    }

    &--level-0#{&}--active {
      @include media-breakpoint-up(xl) {
        color: $brand-700;
        display: flex;
        flex-direction: column-reverse;
        //text-decoration: underline;

        &::before {
          display: block;
          width: 100%;
          height: px-to-rem(1px);
          background: $brand-700;
          content: '';
        }

        &.color-logIn::before {
          background: currentColor;
        }
      }
    }

    &-label {
      display: none;
    }

    &-a11y {
      @include visually-hidden;
    }

    &--level-0 &-a11y {
      @include media-breakpoint-up(xl) {
        display: none;
      }
    }

    &--level-0 &-label {
      @include media-breakpoint-up(xl) {
        display: block;
      }
    }
  }

  &__subnav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $body-bg;
    padding-top: $grid-gutter-width * 1.5;
    display: flex;
    flex-direction: column;
    z-index: 2;

    @include focus-ring;

    @include media-breakpoint-up(xl) {
      padding: $grid-gutter-width;
    }

    &--level-0 {
      @include media-breakpoint-up(xl) {
        z-index: $z-index-nav-overlay;
        height: auto;
        top: 100%;
        margin-top: $grid-gutter-width * 0.75;
        box-shadow: $box-shadow;
        // Keep .02px
        // Chrome requires a unit for variable-fallbacks, even if its zero,
        // but sass will remove any units when the value equals zero
        min-height: max(
          var(--subnav-visible-sidebar, 0.02px),
          var(--subnav-items-height, 0.02px),
          var(--subnav-toggleable-sidebar, 0.02px)
        );
      }
    }

    &--level-1 {
      @include media-breakpoint-up(xl) {
        top: 0;
        left: calc((100% - #{$grid-gutter-width * 2}) * 0.3);
        width: calc((100% - #{$grid-gutter-width * 2}) * 0.4);
        height: auto;
        padding: 0;
        min-height: calc(100% - #{$grid-gutter-width * 2});
        margin: $grid-gutter-width;
        background: $gray-100;
      }
    }

    &-link-list {
      display: contents;

      @include media-breakpoint-up(xl) {
        display: block;
      }
    }
  }

  &__back-button {
    width: $grid-gutter-width * 0.75;
    height: $grid-gutter-width * 0.75;
    display: block;
    border: none;
    padding: 0;
    margin: math.div($grid-gutter-width, 2) $container-padding-x $grid-gutter-width;
    background: none;
    color: inherit;
    order: -1;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    path {
      stroke: $body-color;
      stroke-width: 2px;
    }

    @include media-breakpoint-up(xl) {
      @include visually-hidden-focusable;

      order: 0;
    }
  }

  &__sidebar {
    position: absolute;
    top: 0;
    right: 0;
    background: $body-bg;
    padding: $grid-gutter-width;
    width: calc((100% - #{$grid-gutter-width * 2}) * 0.3 + #{$grid-gutter-width});
    display: none;
    min-height: 100%;
    border-radius: math.div($grid-gutter-width, 4);
    z-index: 10;
  }

  /**
   *  ⠀⠀⠀⠀⠀⢀⠔⠒⠒⠢⡀⠀⠀⢀⠔⠒⠒⠢⡀⠀⠀⠀⠀⠀
   * ⠀⢀⠔⠀⠉⠉⠀⠀⠀⠀⠸⠀⠀⡇⠀⠀⠀⠀⠈⠉⠁⠢⡀⠀
   * ⢠⠁⠀⠀⠀⠀⠀⠀⡠⠐⠈⠉⠉⠁⠒⢄⠀⠀⠀⠀⠀⠀⠈⡆
   * ⢸⠀⠀⠀⠀⠀⢠⠊⠴⠚⠛⠀⠀⠛⠓⠆⠑⡀⠀⠀⠀⠀⢀⡇
   * ⢀⠇⠀⠀⠀⢀⠇⠔⠉⡉⢂⠀⠀⡐⠉⠉⠢⠰⠀⠀⠀⠀⠸⡀
   * ⠈⠀⠀⢠⠄⢼⡘⠀⣤⣧⡀⡆⢠⢀⣴⣄⠀⢃⡧⠀⡄⠀⠀⢣
   * ⠘⢄⠀⢃⠰⠀⠧⡀⢻⡿⣣⣧⣬⣌⢿⡟⠁⡸⠀⠆⠸⠀⣠⠃
   * ⠀⠀⠉⠈⡄⢹⠔⠚⠍⢱⣧⡽⠉⣿⡆⠨⠓⠢⡇⢠⠁⠉⠀⠀
   * ⠀⠀⠀⠀⢇⠈⡀⢁⠘⡀⠻⢶⡾⠟⢀⠃⡈⢀⠃⣸⠀⠀⠀⠀
   * ⠀⠀⠀⠀⠈⢂⠡⡀⠢⡈⠒⠀⠀⠒⢁⠔⢁⠌⡰⠁⠀⠀⠀⠀
   * ⠀⠀⠀⠀⠀⣴⡷⢌⠂⠌⠓⠲⠶⠚⠁⠐⣡⢾⣦⡀⠀⠀⠀⠀
   * ⠀⠀⠀⠀⣸⣿⣿⣶⣷⣄⠀⠀⠀⠀⣠⣾⣷⣷⣿⣧⠀⠀⠀⠀
   * ⠀⠀⠀⠀⢻⣿⣿⣿⣽⣿⣿⣶⣶⣾⣿⣯⣭⣿⣿⡟⠀⠀⠀⠀
   * ⠀⠀⠀⠀⠘⣿⣿⣿⡿⠟⠁⠀⠀⠈⠻⢿⣿⣿⣿⠃⠀⠀⠀⠀
   * ⠀⠀⠀⠀⠀⠈⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠁⠀⠀⠀⠀⠀
   * ⠀⠀⠀⠠⠦⠴⠦⠀⠤⠤⠆⠤⠤⠄⠰⠒⠶⠔⠶⠦⠆⠀⠀⠀
   *
   * Nachträgliche Navi-Änderung: https://support.sup7.at/issues/15341
   */
  &__item--has-subnav#{&}__item--level-0 > &__link-wrapper > &__link {
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
}

#outside-burger-nav {
  --nav-link-color: #{$primary};
  --nav-link-color-hover: #{$primary};
  --nav-background-color: #{$white};

  @include media-breakpoint-up(md) {
    border: none;
    --nav-link-color: #{$white};
    --nav-link-color-hover: #{$white};
    --nav-link-background-color-hover: #{$primary};
    --nav-background-color: #{$primary};
  }

  li {
    padding: math.div($grid-gutter-width, 4) $container-padding-x;
    border: 1px solid $primary;
    border-radius: $btn-border-radius;
    background: var(--nav-background-color);
    @include transition($btn-transition);

    &:hover {
      --nav-link-color: #{$white};
      --nav-background-color: #{$primary};

      @include media-breakpoint-up(md) {
        --nav-background-color: #{$brand-800};
      }
    }

    a {
      text-decoration: none;
      font-weight: $font-weight-bold;
      color: var(--nav-link-color);
    }
  }
}
