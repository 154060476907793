@use 'sass:math';

.meta-nav {
  @include media-breakpoint-up(xl) {
    display: flex;
    align-items: center;
    margin: math.div($grid-gutter-width, 2) 0;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(xl) {
      display: flex;
      margin: 0;
    }
  }

  &__link {
    margin: 0;
    border: none;
    background: none;
    display: flex;
    justify-content: flex-start;
    gap: math.div($grid-gutter-width, 8);
    align-items: center;
    padding: math.div($grid-gutter-width, 4) $container-padding-x;
    text-decoration: none;
    color: $body-color;
    outline-offset: -4px !important;

    &:hover,
    &:focus,
    &:active {
      color: currentColor;
    }

    @include media-breakpoint-up(xl) {
      min-height: 100%;
      justify-content: center;
    }
  }

  &__icon {
    width: px-to-rem(22px);
    height: px-to-rem(18px);
    object-fit: cover;

    @include media-breakpoint-up(xl) {
      display: block;
    }
  }

  &__label {
    &--desktop-visually-hidden {
      @include media-breakpoint-up(xl) {
        @include visually-hidden;
      }
    }
  }
}
