.sub-nav {
  &-enter-active,
  &-leave-active {
    @include motion-safe {
      transition: all 0.3s ease !important;
    }
    @include media-breakpoint-up(xl) {
      transition: none !important;
    }
  }

  &-enter,
  &-leave-to {
    left: 100% !important;
  }
}
