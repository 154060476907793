.frame {
  &.frame-type-kk_contentelements_hero,
  &.frame-type-kk_contentelements_headerpic {
    margin-bottom: $grid-gutter-width;

    @include media-breakpoint-up(ultrawide) {
      margin-bottom: $grid-gutter-width * 2;
    }
  }

  & + &.frame-type-tx_supi_button {
    margin-top: $grid-gutter-width * -2;
    margin-bottom: px-to-rem(16px);

    @include media-breakpoint-up(xl) {
      margin-bottom: px-to-rem(24px);
    }

    h3 {
      padding-top: px-to-rem(16px);

      @include media-breakpoint-up(xl) {
        padding-top: px-to-rem(24px);
      }
    }

    @include media-breakpoint-up(ultrawide) {
      margin-top: $grid-gutter-width * -4;
    }
  }

  main & {
    margin-bottom: $grid-gutter-width * 2;

    @include media-breakpoint-up(ultrawide) {
      margin-bottom: $grid-gutter-width * 4;
    }

    &-type-header {
      margin-bottom: 0;
    }

    // make sure, a frame with bg-color has no space beneath the next element
    // when it is followed by a .frame element
    // and do not remove spacings on startpage be layout
    body:not(.belayout-startsite) &.bg-light:has(+ .frame) {
      margin-bottom: 0;
    }

    body:not(.belayout-blogdetail) &:last-of-type {
      margin-bottom: 0;
    }
  }
}

// there are text-media ce with color options. when this option is set, there
// has to be a negativ margin by the following elements
.background-color-light + .frame,
.frame:not(.frame-type-header) + .background-color-light {
  @include media-breakpoint-up(ultrawide) {
    margin-top: $grid-gutter-width * -2;
  }
}
