.blog {
  &__detail {
    &-header-info {
      & > * {
        flex: 0 0 auto;
      }

      @include media-breakpoint-up(md) {
        time svg {
          margin-top: 4px;
        }
      }
    }
  }
}
