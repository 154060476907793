@use 'sass:math';

.pagination-datatables {
  margin-top: $grid-gutter-width;

  &__list {
    --custom-marker-display: none;

    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
  }

  &__item {
    &--active {
      color: $primary;
      text-decoration: underline;
    }
  }

  &__button {
    margin: 0;
    padding: math.div($grid-gutter-width, 2);
    border: none;
    background: none;
    font: inherit;
    color: inherit;
    display: block;
  }

  &__icon {
    height: math.div($grid-gutter-width, 2);
    aspect-ratio: 1 / 2;
    display: block;
    margin-top: -1px;

    &--rotated {
      transform: rotateZ(180deg);
      transform-origin: center center;
    }
  }
}

.pagination {
  font-size: px-to-rem(18px);

  a {
    color: $body-color;
    text-decoration: none;
    display: flex;
    width: $grid-gutter-width;
    height: $grid-gutter-width;
    justify-content: center;
    align-items: center;
  }

  .active a {
    color: map-get($theme-colors, 'primary');
    font-weight: $font-weight-bold;
  }

  .page-link {
    border: 0;
    padding: 3px;
  }
}

.pagination-detail {
  li {
    flex: 0 0 50%;
  }
}
