@mixin focus-ring-style {
  outline: currentColor dashed 2px;
  outline-offset: 2px;
}

@mixin focus-ring {
  &:focus-visible {
    @include focus-ring-style;
  }

  @supports not selector(:focus) {
    &:focus {
      @include focus-ring-style;
    }
  }
}
