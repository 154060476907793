.frame-type-kk_contentelements_facts_figures,
.frame-type-eeproducts_applications {
  .facts {
    &-wrapper {
      --value-font-size: 56px;
      --gap: #{px-to-rem(40px)};
      --flex-direction: column;
      --facts-gutter: #{$grid-gutter-width * 0.5};

      display: grid;
      grid-auto-rows: min-content;
      grid-template-columns:
        minmax(var(--facts-gutter), 1fr) calc(var(--container-max-width) - calc(var(--facts-gutter) * 2))
        minmax(var(--facts-gutter), 1fr);

      @include media-breakpoint-up(md) {
        --gap: #{px-to-rem(32px)};
        --flex-direction: row;

        grid-template-columns: minmax(var(--facts-gutter), 1fr) var(--container-max-width) minmax(
            var(--facts-gutter),
            1fr
          );
      }

      @include media-breakpoint-up(xl) {
        --value-font-size: 75px;
      }

      &__inner {
        grid-row: 2;
        grid-column: 2;
        display: flex;
        flex-direction: var(--flex-direction);
        gap: var(--gap);
      }
    }

    &-entry {
      flex-basis: 0;
      font-style: normal;
      font-weight: 700;
      line-height: 1.2;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > span {
        text-align: center;
        text-wrap: balance;
      }

      &__figure {
        font-size: var(--value-font-size);
        color: __get($theme-colors, 'primary');
        display: flex;
      }

      &__value {
        text-align: end;
      }
    }
  }
}
