@use 'sass:math';

:root {
  --page-header-height: #{$grid-gutter-width * 1.5};
  --logo-scale: 1;
  --header-margin: #{$grid-gutter-width * 0.5};
  --logo-aspect-ratio: 300 / 134;

  @include media-breakpoint-up(xl) {
    --page-header-height: #{px-to-rem(140px)};
    --header-margin: 0;
    --logo-aspect-ratio: 260 / 134;
  }

  &:has(#outside-burger-nav) {
    --page-header-height: #{$grid-gutter-width * 2};
    --logo-scale: 0.75;
    --header-margin: 0;

    @include media-breakpoint-up(xl) {
      --page-header-height: #{px-to-rem(140px)};
      --logo-scale: 1;
    }
  }
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $white;
  z-index: $z-index-page-header;
  margin-bottom: var(--header-margin);
}

.page-header {
  height: var(--page-header-height);
  padding: 0;
  font-size: 1rem;

  @include media-breakpoint-up(xl) {
    padding: $grid-gutter-width * 0.75 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    z-index: $z-index-page-header;
  }

  &__content {
    height: 100%;
    padding: math.div($grid-gutter-width * 0.75, 2) 0;
    z-index: $z-index-page-header;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background: $body-bg;
    position: relative;

    @include media-breakpoint-up(xl) {
      position: static;
      display: contents;
    }
  }

  &__logo {
    display: block;
    height: 100%;
    aspect-ratio: var(--logo-aspect-ratio);
    max-height: 4rem;

    transform: scale(var(--logo-scale));
    transform-origin: left;
  }

  &__button {
    display: block;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    height: 24px;
    aspect-ratio: 1 / 1;
    color: inherit;

    @include media-breakpoint-up(xl) {
      display: none;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    path {
      stroke: currentColor;
      stroke-width: 2px;
    }
  }
}
