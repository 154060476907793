@use 'sass:math';

.whitepaper {
  &-grid {
    display: grid;
    grid-template-columns: auto;
    grid-auto-rows: min-content;

    &__2col {
      grid-template-columns: percentage(math.div(7, 12)) percentage(math.div(5, 12));
    }

    &__text {
      grid-column: 1/3;
      grid-row: 2;

      @include media-breakpoint-up(md) {
        grid-column: 1;
        grid-row: 1;
      }
    }

    &__form {
      grid-column: 1/3;
      grid-row: 3;

      @include media-breakpoint-up(xl) {
        grid-column: 1;
        grid-row: 2;
      }
    }

    &__media {
      grid-column: 1/3;
      grid-row: 1;

      @include media-breakpoint-up(md) {
        grid-column: 2;
        grid-row: 1;
      }

      @include media-breakpoint-up(xl) {
        grid-column: 2;
        grid-row: 1 / 3;
      }
    }
  }
}
