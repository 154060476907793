.gallery {
  &-wrap {
    @include make-row;
    row-gap: $grid-gutter-width;

    &.gallery-items-1 {
      margin: 0;
    }
  }

  &__item {
    img {
      object-fit: cover;
    }

    @each $i in (1, 2, 3, 4, 6) {
      .gallery-items-#{$i} & {
        @include make-col(12);
        @include make-col-ready;
      }
    }

    .gallery-items-1 & {
      padding: 0;
    }

    .gallery-items-2 & {
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
    }

    .gallery-items-3 & {
      @include media-breakpoint-up(md) {
        @include make-col(4);
      }
    }

    .gallery-items-4 & {
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(3);
      }
    }

    .gallery-items-6 & {
      @include media-breakpoint-up(md) {
        @include make-col(4);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(2);
      }
    }
  }
}
