@use 'sass:math';

:root {
  --chat-button-position-bottom: #{px-to-rem(25px)};
  --chat-button-position: auto var(--chat-button-position-bottom) var(--chat-button-position-bottom) auto;

  --dialog-right: auto;
  --dialog-left: auto;
  --dialog-width: 100%;
  --dialog-bottom: calc(var(--chat-button-position-bottom) + #{$grid-gutter-width * 2});
  --dialog-arrow-right: #{$grid-gutter-width * 1.5};

  --avatar-shift: 10px;

  @include media-breakpoint-up(md) {
    --dialog-width: 350px;
    --dialog-right: 0;
  }
}

.chat {
  &-button {
    .icon-small {
      --icon-w: 24px;
      --icon-h: 24px;
      --icon-margin: 0;
      color: #{map-get($theme-colors, 'white')};
    }

    display: flex;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    padding: 0;
    gap: #{$grid-gutter-width * 0.5};
    background: $primary;
    border: none;
    border-radius: 100%;

    position: fixed;
    inset: var(--chat-button-position);
    transform: translateX(var(--chat-button-transform));
    z-index: 1020;

    @include motion-safe {
      transition: transform 0.2s ease-in-out;
    }

    & + dialog {
      --heading-2-size: #{px-to-rem(24px)};
      --heading-2-mb: 0;

      position: fixed;
      bottom: var(--dialog-bottom);
      right: var(--dialog-right);
      left: var(--dialog-left);
      width: var(--dialog-width);

      border: none;
      background: transparent;
      padding-block: #{$grid-gutter-width};
      z-index: 1020;

      & > div {
        & > div {
          position: relative;
          z-index: 1020;

          box-shadow: $box-shadow;
          background: map-get($theme-colors, 'white');
          padding: #{$grid-gutter-width * 0.5};

          h2 {
            &:after {
              display: block;
              content: '';
              max-width: $grid-gutter-width * 2;
              height: px-to-rem(2px);
              background: $primary;
              margin: math.div($grid-gutter-width, 2) 0;
            }
          }

          p {
            font-size: px-to-rem(16px);
            line-height: 1.3;
          }
        }

        &:before {
          content: '';
          position: fixed;
          background: fade-out($white, 0.2);
          inset: 0;
          z-index: 1010;
        }

        &:after {
          content: '';
          position: absolute;
          width: $grid-gutter-width * 0.875;
          aspect-ratio: 1 / 1;
          right: var(--dialog-arrow-right);
          bottom: #{$grid-gutter-width};
          transform: translate(0%, 50%) rotateZ(45deg);
          transform-origin: center center;
          background: map-get($theme-colors, 'white');
          clip-path: polygon(-10% 110%, 110% 110%, 110% -10%);
          box-shadow: $box-shadow;
          z-index: 1020;
        }
      }
    }
  }
}

#tidio-chat-iframe.hide-chat {
  transform: translateX(150%) !important;
}
