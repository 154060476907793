@use 'sass:math';

:root {
  --heading-text-wrap: initial;

  @each $order, $sizes in __get($plus-icon, 'sizes') {
    @each $breakpoint, $attributes in $sizes {
      @include media-breakpoint-up($breakpoint) {
        --heading-#{$order}-ico-pt: #{px-to-rem(__get($attributes, 'top'))};
        --heading-#{$order}-ico-pl: #{px-to-rem(__get($attributes, 'left'))};
        --heading-#{$order}-ico-w: #{px-to-rem(__get($attributes, 'w'))};
        --heading-#{$order}-ico-h: #{px-to-rem(__get($attributes, 'h'))};
        --heading-#{$order}-ico-mb: #{px-to-rem(__get($attributes, 'margin'))};
      }
    }
  }
}

@each $order, $sizes in __get($plus-icon, 'sizes') {
  h#{$order},
  .h#{$order} {
    text-wrap: var(--heading-text-wrap);

    &.ico-plus {
      --heading-#{$order}-color: #{$body-color};

      position: relative;
      padding-top: var(--heading-#{$order}-ico-pt);
      padding-left: var(--heading-#{$order}-ico-pl);
      margin-bottom: var(--heading-#{$order}-ico-mb);

      &.white {
        --heading-#{$order}-color: #{map-get($theme-colors, 'white')};

        &:before {
          background-image: url(map-get($plus-icon, 'icon-white'));
        }
      }

      &:before {
        content: '';
        background-image: url(map-get($plus-icon, 'icon'));
        position: absolute;
        top: 0;
        left: 0;
        width: var(--heading-#{$order}-ico-w);
        height: var(--heading-#{$order}-ico-h);
        background-size: var(--heading-#{$order}-ico-h) var(--heading-#{$order}-ico-w);
      }

      .hero & {
        padding-left: 0;

        &:before {
          left: -12px;

          @include media-breakpoint-up(xl) {
            left: -16px;
          }
        }
      }
    }
  }
}

.infotext {
  h2 {
    $sizes: __get($heading-sizes, 3);
    @each $breakpoint, $values in $sizes {
      @include media-breakpoint-up($breakpoint) {
        font-size: px-to-rem(__get($values, 'size'));
        margin-bottom: px-to-rem(__get($values, 'margin'));
      }
    }
  }
}

h3.footer__heading.h4 {
  color: var(--bs-body-color);
}
