.bodytext {
  &:last-child {
    margin-bottom: 0;
  }

  .teasertext & {
    font-weight: $font-weight-bold;
  }

  .copytext & {
    & + h2,
    & + h3 {
      margin-top: $grid-gutter-width;
    }
  }
}

.text {
  ul + a {
    margin-top: $grid-gutter-width * 0.75;
  }
}
