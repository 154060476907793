.animate {
  --teaser-opacity: 1;
  --teaser-translateY: 0;
  --teaser-img-translateY: 0;
}

@include motion-safe {
  .card.card-products,
  .card.card-news,
  .card.card-segments {
    &:has(a) {
      transition: box-shadow 0.2s ease-in-out;

      &:hover {
        --box-shadow-y: 10px;
        --box-shadow-x: 0px;
        --box-shadow-blur: 20px;
        --box-shadow-color-opacity: 0.2;
      }

      &:has(img) {
        figure {
          overflow: hidden;
        }

        img {
          transition: all 0.2s ease-in-out;
          transform: scale(var(--hover-scale, 1)) rotate(var(--hover-rotate, 0deg));
        }

        &:hover {
          --hover-scale: 1.02;
          --hover-rotate: -0.5deg;
        }
      }
    }
  }
}
