.document-list {
  font-size: 1rem;

  // if per url is selected for a specific download
  &.hide-product-column {
    .table {
      thead,
      tbody {
        th,
        td {
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}
