@use 'sass:math';

body.belayout-products {
  --heading-2-mb: #{$grid-gutter-width};
}

:root {
  --tech-grid-col: 2;
  --tech-data-col: 1 / -1;
  --tech-dd-gap: #{$grid-gutter-width * 0.25};

  --measurement-width: #{$grid-gutter-width};

  @include media-breakpoint-up(xl) {
    --tech-grid-col: 4;
    --tech-dd-gap: #{$grid-gutter-width * 0.5};

    --measurement-width: #{$grid-gutter-width * 1.5};
  }
}

.product-detail {
  &__header {
    --heading-2-mb: #{$grid-gutter-width * 0.5};

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      'title'
      'measurements'
      'content';
    column-gap: $grid-gutter-width;

    @include media-breakpoint-up(xl) {
      grid-template-columns: 9fr 300px;
      grid-template-rows: auto auto;
      grid-template-areas:
        'title measurements'
        'content measurements';
    }

    &-title {
      grid-area: title;
    }

    &-content {
      grid-area: content;
      margin-bottom: $grid-gutter-width;

      .epluselist {
        @include media-breakpoint-up(md) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: $grid-gutter-width * 1.25;
        }

        li {
          font-weight: 700;
        }
      }
    }
  }

  &__measurement {
    grid-area: measurements;

    &-item {
      display: block;
      width: var(--measurement-width);

      img {
        aspect-ratio: 1;
        object-fit: cover;
      }
    }
  }

  &__key-features {
    ul {
      margin-bottom: 0;

      @include media-breakpoint-up(xl) {
        columns: 2;
        padding-left: 0;
      }

      li {
        margin-bottom: #{$grid-gutter-width * 0.5};

        @include media-breakpoint-up(xl) {
          margin-left: px-to-rem(28px);
        }
      }
    }
  }
  &__technical-data {
    &__grid {
      display: grid;
      grid-template-columns: repeat(var(--tech-grid-col), 1fr);
    }

    display: grid;
    grid-template-columns: subgrid;
    grid-column: var(--tech-data-col);
    background: var(--tech-bg-color);

    @include media-breakpoint-down(xl) {
      &:nth-child(odd) {
        background: #{$gray-100};
      }
    }

    @include media-breakpoint-up(xl) {
      &:nth-child(4n + 1),
      &:nth-child(4n + 2) {
        background: #{$gray-100};
      }

      &:nth-child(odd) {
        --tech-data-col: 1 / span 2;

        &:last-child {
          --tech-data-col: 1 / -1;
        }
      }

      &:nth-child(even) {
        --tech-data-col: 3 / -1;
      }
    }

    dd,
    dt {
      display: flex;
      flex-direction: column;
      gap: var(--tech-dd-gap);
    }
  }
}
