.dataTables {
  &_wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &_length,
  &_filter,
  &_info,
  &_paginate {
    flex: 1 0 50%;
    display: flex;
    align-items: center;
    background: $gray-200;
    padding: $grid-gutter-width * 0.25;
  }

  &_filter,
  &_paginate {
    justify-content: flex-end;

    input {
      margin-left: $grid-gutter-width * 0.25;
    }
  }

  &_paginate,
  &_paginate span {
    display: flex;
    gap: $grid-gutter-width * 0.5;

    a {
      cursor: pointer;
    }
  }
}

.dataTable {
  flex: 1 0 100%;
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;

  td,
  th {
    vertical-align: top;
    padding: $grid-gutter-width * 0.25;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-right: $grid-gutter-width * 0.25;
  }

  a {
    &[download] {
      white-space: nowrap;
    }
  }
}
