@use 'sass:math';

.frame-type-theme_tabbed_content,
.frame-type-eeproducts_products {
  --border-offset: 2px;
  --tab-color: #{$gray-600};
  --sticky-top: 3rem;

  @include media-breakpoint-up(xl) {
    --sticky-top: 140px;
  }

  .nav-tabs {
    border-bottom: var(--border-offset) solid $gray-300;
    margin-inline: -2px;
    // prevent cutted outlines on focus states
    gap: 4px;

    .nav-link {
      border: none;
      margin-bottom: calc(var(--border-offset) * -1);
      padding: $grid-gutter-width * 0.5;
      color: var(--tab-color);
      font-weight: 700;
      line-height: 1.3;

      &.active {
        --tab-color: #{$primary};
        border-bottom: var(--border-offset) solid var(--tab-color);
      }
    }
  }

  .select-holder {
    border-bottom: var(--border-offset) solid $gray-300;
    position: relative;
    margin-inline: -2px;

    svg {
      position: absolute;
      top: 50%;
      right: $grid-gutter-width * 0.5;
      margin-top: -12px;
      z-index: 0;
    }

    .nav-select {
      padding-block: $grid-gutter-width * 0.5;
      appearance: none;
      border: none;
      width: 100%;
      color: $primary;
      font-weight: 700;
      font-size: px-to-rem(18px);
      position: relative;
      z-index: 1;
      background: transparent;
    }
  }

  .position-sticky {
    top: var(--sticky-top);
    z-index: 2;
  }
}

.frame-type-theme_tabbed_content_extended {
  .tab-content__extended {
    --heading-2-color: #{map-get($theme-colors, 'copytext')};

    --tab-item-display: block;
    --tab-item-row: auto;
    --tab-item-order: 1;

    --tab-gap: #{$grid-gutter-width};
    --tab-height: #{px-to-rem(218px)};
    --tab-font-size: #{px-to-rem(20px)};
    --tab-font-weight: 700;

    --tab-grid-column-count: 2;
    --tab-grid-row-count: 3;

    --tab-grid-columns: repeat(var(--tab-grid-column-count), minmax(auto, 1fr));
    --tab-grid-rows: repeat(var(--tab-grid-row-count), auto);
    --tab-grid-gap: #{$grid-gutter-width * 0.5};

    --tab-grid-row-btn: 2;
    --tab-grid-row-content: -1;
    --tab-grid-column-content: 1 / -1;

    --icon-w: 32px;
    --icon-h: var(--icon-w);
    --icon-margin: 0;
    --ico-color: #{$primary};

    @include media-breakpoint-up(md) {
      --icon-w: 56px;
      --tab-item-display: grid;
      --tab-item-row: 1 / -1;
      --tab-grid-gap: #{$grid-gutter-width * 0.75};
    }

    @include media-breakpoint-up(xl) {
      --tab-font-size: #{px-to-rem(24px)};

      --tab-grid-column-count: 3;
      --tab-grid-row-count: 2;
      --tab-grid-row-btn: 1;
      --tab-grid-gap: #{$grid-gutter-width * 1.75} #{$grid-gutter-width};

      --icon-w: 64px;
    }

    @include media-breakpoint-up(md) {
      .collapsing {
        transition: none;
      }
    }

    &-list {
      display: grid;
      grid-template-columns: var(--tab-grid-columns);
      grid-template-rows: var(--tab-grid-rows);
      gap: var(--tab-grid-gap);

      &__item {
        display: var(--tab-item-display);
        order: var(--tab-item-order);
        grid-template-rows: subgrid;
        grid-template-columns: subgrid;
        grid-row: var(--tab-item-row);
        grid-column: 1 / -1;

        &:has(.show) .tab-content__extended-list__button {
          @include media-breakpoint-up(md) {
            pointer-events: none;
          }
        }

        &[data-item='0'] {
          .tab-content__extended-list__button {
            --tab-grid-column-btn: 1;
          }
        }

        &[data-item='1'] {
          .tab-content__extended-list__button {
            --tab-grid-column-btn: 2;
          }
        }

        &[data-item='2'] {
          --tab-item-order: 0;

          @include media-breakpoint-up(md) {
            --tab-item-order: 1;
          }

          .tab-content__extended-list__button {
            --tab-grid-column-btn: 1/-1;
            --tab-grid-row-btn: 1;

            @include media-breakpoint-up(xl) {
              --tab-grid-column-btn: 3;
            }
          }
        }
      }

      &__button {
        border: none;
        background: transparent;
        box-shadow: $box-shadow;
        width: 100%;

        @include media-breakpoint-up(md) {
          border-bottom: 4px solid transparent;
        }

        z-index: 1;

        grid-column: var(--tab-grid-column-btn);
        grid-row: var(--tab-grid-row-btn);

        &[aria-expanded='true'] {
          border-bottom: 4px solid $primary;

          .tab-content__extended-list__chevron {
            rotate: -180deg;
          }
        }
      }

      &__content {
        @include reset-bs-grid;

        grid-row: var(--tab-grid-row-content);
        grid-column: var(--tab-grid-column-content);

        z-index: 1;
      }

      &__label {
        --icon-w: 24px;
        --icon-h: var(--icon-w);
        --icon-margin: 0;

        @include media-breakpoint-up(md) {
          --icon-w: 32px;
        }

        font-size: var(--tab-font-size);
        font-weight: var(--tab-font-weight);
      }

      &__chevron {
        @include motion-safe {
          transition: 0.2s ease-in-out;
        }
      }

      &__phone {
        flex: 0 0 calc(50% - #{($grid-gutter-width * 0.75) * 0.5});
      }
    }
  }
}
