@use 'sass:math';

:root {
  --top-search-width: auto;
  --top-search-height: 40px;
  --top-search-btn-width: 40px;
  --top-search-input-width: auto;

  @include media-breakpoint-up(xl) {
    --top-search-width: 125px;
    --top-search-input-width: 85px;
  }
}

.top-search {
  &__wrapper {
    width: var(--top-search-width);
    height: var(--top-search-height);
    background: $gray-100;
    margin: $grid-gutter-width $grid-gutter-width * 0.25;

    @include media-breakpoint-up(xl) {
      margin: 0;
    }
  }

  &__form {
    display: flex;

    @include media-breakpoint-up(xl) {
      padding-left: calc(var(--top-search-width) - var(--top-search-btn-width));
    }

    height: var(--top-search-height);
  }

  &__input {
    margin: 0;
    padding: math.div($grid-gutter-width, 4) $container-padding-x;
    border: none;
    background: $gray-100;
    font: inherit;
    color: $body-color;
    width: var(--top-search-input-width);
    height: 100%;
    flex: 1 0 auto;

    @include media-breakpoint-up(xl) {
      position: absolute;
      top: 0;
      bottom: 0;
      right: var(--top-search-btn-width);
      transition: width 0.2s ease-in-out;

      &:focus {
        width: calc(var(--top-search-width) * 3);
      }
    }

    &::placeholder {
      color: inherit;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 0;
    padding: 0 math.div($grid-gutter-width, 4);
    background: transparent;
    width: var(--top-search-btn-width);
    height: 100%;

    &:focus-visible {
      z-index: 1;
    }

    svg {
      display: block;
      width: math.div($grid-gutter-width, 1.5);
      aspect-ratio: 1 / 1;
    }

    path {
      fill: currentColor;
    }
  }
}

//.top-search {
//  margin: math.div($grid-gutter-width, 4) $container-padding-x;
//  background: $gray-100;
//
//  @include media-breakpoint-up(xl) {
//    margin: 0 0 0 $container-padding-x;
//    max-width: px-to-rem(120px);
//  }
//
//  &__form {
//    display: flex;
//    flex-wrap: nowrap;
//    align-items: stretch;
//  }
//
//  &__input {
//    display: block;
//    margin: 0;
//    padding: math.div($grid-gutter-width, 4) $container-padding-x;
//    border: none;
//    background: transparent;
//    font: inherit;
//    flex-grow: 1;
//    color: $body-color;
//    width: 100%;
//
//    &::placeholder {
//      color: inherit;
//    }
//
//    @include media-breakpoint-up(xl) {
//      padding-right: 0;
//      padding-left: math.div($grid-gutter-width, 2);
//    }
//  }
//
//  &__button {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    width: fit-content;
//    flex-grow: 0;
//    flex-shrink: 0;
//    border: none;
//    margin: 0;
//    padding: 0 math.div($grid-gutter-width, 4);
//    background: transparent;
//
//    svg {
//      display: block;
//      width: math.div($grid-gutter-width, 1.5);
//      aspect-ratio: 1 / 1;
//    }
//
//    path {
//      fill: currentColor;
//    }
//  }
//}
