:root {
  --input-select-font-size: var(--bs-body-font-size);
}

.form {
  &-fields {
    &__search {
      display: grid;
      grid-auto-columns: auto;
      grid-auto-rows: auto;

      & > * {
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }

      button {
        height: 100%;
        border: none;
        background: none;
        padding: 0 $grid-gutter-width * 0.5;
        margin: 0;
        justify-self: end;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &-stepper {
    &__list {
      &-item {
        flex: 1 1 0;

        span {
          margin: 0 $grid-gutter-width * 0.25;
          border-radius: 2px;
          font-weight: 700;
          width: 45px;
          height: 45px;
        }

        &:before,
        &:after {
          --bg-color: #{__get($theme-colors, 'copytext')};
          content: '';
          height: 2px;
          width: calc((100% - 45px - 8px) / 2);
          background: var(--bg-color);
        }

        &.previous {
          &:before,
          &:after {
            --bg-color: #{__get($theme-colors, 'primary')};
          }
        }

        &.current {
          &:before {
            --bg-color: #{__get($theme-colors, 'primary')};
          }
        }

        &:first-child {
          justify-content: end;

          &:before {
            content: unset;
          }
        }

        &:last-child {
          justify-content: start;

          &:after {
            content: unset;
          }
        }
      }
    }
  }

  &-navigation {
    .btn-group {
      &:has(> button:nth-of-type(2)) {
        justify-content: space-between;
      }

      &:not(:has(> button:nth-of-type(2))) {
        justify-content: flex-end;
      }
    }
  }

  &-statictext {
    margin-bottom: $grid-gutter-width * 0.125;
    font-size: 16px;

    h2 {
      color: __get($theme-colors, 'copytext');
    }
  }

  &-select {
    font-size: var(--input-select-font-size);
  }
}

.accredited-calibration-service {
  .form {
    &-check:not(.form-check-inline) {
      margin-bottom: $grid-gutter-width * 0.25;
    }

    &-label + .input:has(> .inputs-list),
    &-label + .has-dependency:has(> .inputs-list) {
      margin-top: $grid-gutter-width * 0.125;
    }
  }

  .dynamic-list {
    &__item {
      margin-bottom: $grid-gutter-width;

      .characteristics {
        .form-check {
          &-inline {
            margin: 0;
            padding: $grid-gutter-width * 0.25;
            border-radius: 2px;
            border: 1px solid __get($theme-colors, 'input');
            min-width: 100px;
            display: inline-grid;

            &:has(input:checked),
            &:focus-within {
              border-color: __get($theme-colors, 'primary');
              background-color: $brand-200;
            }

            &:focus-within {
              @include focus-ring-style;
              box-shadow: $input-focus-box-shadow;
            }
          }

          &-input {
            grid-row: 1;
            grid-column: 1;
            background: none;
            margin: 0;
            border: none;
            width: 100%;
            height: 100%;
            z-index: 1;

            &:hover {
              cursor: pointer;
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          &-label {
            grid-column: 1;
            grid-row: 1;

            svg {
              --icon-w: 35px;
              --icon-h: 35px;
              --icon-margin: 0;

              place-self: center;

              @include media-breakpoint-up(xl) {
                --icon-w: 45px;
                --icon-h: 45px;
              }
            }

            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
