@mixin reset-bs-grid {
  & > .row {
    margin: 0;

    & > [class*='col-'] {
      padding: 0;
      flex-basis: 100%;
      width: 100%;
    }
  }
}
