@use 'sass:math';

.language-select {
  position: relative;

  @include media-breakpoint-up(xl) {
    padding: 0;
  }

  &__label {
    display: flex;
    background: transparent;
    margin: 0;
    border: none;
    font: inherit;
    color: inherit;
    align-items: center;
    justify-content: flex-start;
    gap: math.div($grid-gutter-width, 8);
    background: $white;
    pointer-events: none;
    z-index: 1;
    position: relative;
    padding: math.div($grid-gutter-width, 4) $container-padding-x;
    outline-offset: -4px !important;

    @include media-breakpoint-up(xl) {
      pointer-events: all;
      margin-right: -$container-padding-x;
    }
  }

  &__chevron {
    display: block;
    width: px-to-rem(10px);
    aspect-ratio: 2 / 1;
    pointer-events: none;
  }

  &__globe {
    display: block;
    width: 1rem;
    aspect-ratio: 1 / 1;
    pointer-events: none;
  }

  &__list {
    position: absolute;
    display: none;
    right: 0;
    top: 100%;
    margin: 0;
    list-style: none;
    box-shadow: $box-shadow;
    z-index: 999;
    padding: 0;
    background: $white;
    min-width: 100%;
  }

  &__link {
    display: block;
    text-decoration: none;
    color: inherit;
    padding: math.div($grid-gutter-width, 4) math.div($grid-gutter-width, 8) math.div($grid-gutter-width, 4)
      math.div($grid-gutter-width, 4);

    &--selected {
      color: $brand-700;
    }
  }

  &__select {
    display: block;
    position: absolute;
    top: math.div($grid-gutter-width, 4);
    left: $container-padding-x;
    right: $container-padding-x;
    bottom: math.div($grid-gutter-width, 4);
    cursor: pointer;
  }
}
