@use 'sass:math';

.icon-teaser {
  --custom-marker-display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: stretch;

  @include make-row;

  &__item {
    margin-bottom: $grid-gutter-width;

    @include make-col-ready;
    @include make-col(12);

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(4);
    }
  }

  &__link {
    display: block;
    box-shadow: $box-shadow;
    height: 100%;

    &,
    &:hover,
    &:focus,
    &:active {
      color: inherit;
      text-decoration: none;
    }
  }

  &__figure {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 3 / 2;
    width: 100%;
    background: linear-gradient(to bottom left, $gray-100, $gray-200);

    svg {
      display: block;
      width: px-to-rem(85px);
      height: px-to-rem(85px);
    }
  }

  &__content {
    padding: math.div($grid-gutter-width, 2);
  }

  &__title {
    display: block;
    margin-bottom: math.div($grid-gutter-width, 2);
  }

  &__description {
    margin-bottom: 0;
  }
}
