.btn {
  &-primary {
    @include button-variant(
      $primary,
      $primary,
      $white,
      $brand-800,
      $brand-800,
      $white,
      $brand-900,
      $brand-900,
      $white,
      $gray-300,
      $gray-300,
      $gray-500
    );
    @include button-custom-variant($primary, $primary, $white, $brand-800);
  }

  &-secondary {
    @include button-variant(
      $secondary,
      $secondary,
      $white,
      $gray-700,
      $gray-700,
      $white,
      $gray-800,
      $gray-800,
      $white,
      $gray-300,
      $gray-300,
      $gray-500
    );
    @include button-custom-variant($secondary, $secondary, $white, $gray-700);
  }

  &-white {
    @include button-variant(
      map-get($theme-colors, 'white'),
      map-get($theme-colors, 'white'),
      map-get($theme-colors, 'primary'),
      $gray-300,
      $gray-300,
      map-get($theme-colors, 'primary'),
      $gray-100,
      $gray-100,
      map-get($theme-colors, 'primary'),
      $gray-300,
      $gray-300,
      $gray-500
    );
    @include button-custom-variant(
      map-get($theme-colors, 'white'),
      map-get($theme-colors, 'white'),
      map-get($theme-colors, 'primary'),
      $gray-100
    );
  }

  &-outline-primary {
    @include button-outline-variant($primary, $brand-800, $white, $brand-900, $brand-900);
    @include button-custom-outline-variant($primary);
  }

  &-outline-secondary {
    @include button-outline-variant($secondary, $gray-700, $white, $gray-800, $gray-800);
    @include button-custom-outline-variant($secondary);
  }

  @each $color, $value in $theme-colors {
    &-outline-#{$color} {
      &:disabled,
      &.disabled {
        border-color: $gray-300;
        color: $gray-500;
      }
    }
  }

  &-plain {
    border: none;
    background: none;
  }

  &-md {
    padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;
  }
}

p.bodytext:has(.btn + .btn) {
  display: flex;
  gap: $grid-gutter-width * 0.5;

  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
  }
}
