:root {
  --underline-offset: 5px;
  --underline-thickness: 1px;
}

a {
  main &,
  footer & {
    text-decoration-line: underline;
    text-underline-offset: var(--underline-offset);
  }

  &.plain-link {
    text-decoration: none;
    color: $body-color;
  }

  &:hover svg.fill-link {
    fill: $link-hover-color !important;
  }
}
