@use 'sass:string';

@import '../../../node_modules/@splidejs/splide/src/css/core/index';

.splide {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0;
  visibility: visible;

  &__arrows,
  &__track,
  &__pagination {
    grid-column: string.unquote('1 / span 2');
  }

  &__arrows {
    grid-row: 2;
    display: flex;
    justify-content: space-between;
  }

  &__arrow {
    border: none;
    background: none;
    padding: ($grid-gutter-width * 0.5) ($grid-gutter-width * 0.25);

    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width * 0.5;
    }

    cursor: pointer;

    svg {
      width: px-to-rem(12px);
      height: px-to-rem(24px);
    }

    &--next {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__track {
    grid-row: 1;
  }

  &__list {
    --grid-gutter-width: #{$grid-gutter-width};
  }

  &__pagination {
    grid-row: 2;
    display: flex;
    gap: $grid-gutter-width * 0.25;

    @include media-breakpoint-up(md) {
      gap: $grid-gutter-width * 0.5;
    }

    padding: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: center;

      // visually better centered
      margin-top: 4px;
    }

    &__page {
      border: none;
      padding: 0;
      background: $gray-500;
      height: px-to-rem(8px);
      margin: 0;
      width: px-to-rem(24px);

      @include media-breakpoint-down(sm) {
        .hero & {
          width: px-to-rem(24px);
        }
      }

      border-radius: 1px;

      &.is-active {
        width: px-to-rem(48px);

        @include media-breakpoint-down(sm) {
          .hero & {
            width: px-to-rem(32px);
          }
        }

        .splide__pagination__page--indicator {
          transform: scaleX(1);
        }
      }

      &--indicator {
        display: block;
        height: 100%;
        background: $body-color;
        transform: scaleX(0);
        border-radius: 1px;
      }
    }

    &-numbers {
      li {
        @include media-breakpoint-down(xl) {
          display: none;
          margin: 0;

          button,
          span {
            background: none;
          }

          .visually-hidden {
            position: relative !important;
          }

          &.is-active {
            display: flex;
          }
        }
      }
    }
  }

  &__controls {
    &-btn {
      cursor: pointer;

      &--play {
        svg {
          height: px-to-rem(24px);
          width: px-to-rem(24px);
        }
      }
    }
  }

  &.is-destroyed & {
    &__list {
      display: flex !important;
      gap: $grid-gutter-width !important;
    }

    &__arrows {
      display: none !important;
    }

    &__pagination {
      display: none !important;
    }
  }
}
