.usp {
  &-wrapper {
    --img-width: 256px;
    --img-transition: opacity 0.35s ease;

    &[data-count='1'] {
      .accordion-button:after {
        display: none;
      }
    }

    @include media-breakpoint-up(md) {
      --img-width: 320px;
    }

    @include media-breakpoint-up(xl) {
      --img-width: 458px;
    }

    .accordion {
      &-button {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.2;
        hyphens: auto;

        &[aria-expanded='true'] {
          pointer-events: initial;

          @include media-breakpoint-up(lg) {
            pointer-events: none;
          }
        }
      }

      &-image {
        display: grid;

        figure {
          grid-row: 1;
          grid-column: 1;
          width: var(--img-width);

          &.show {
            opacity: 1;
            z-index: 1;

            @include motion-safe() {
              transition: var(--img-transition);
            }
          }

          &.collapsing {
            height: auto;
            opacity: 0;

            @include motion-safe() {
              transition: var(--img-transition);
            }
          }

          &.collapse:not(.show) {
            opacity: 0;
            display: block;
          }
        }
      }
    }
  }
}
