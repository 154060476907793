@use 'sass:math';

.ce-uploads {
  --custom-marker-display: none;

  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    border-top: 1px solid $gray-300;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: inherit;
    text-decoration: none;
    padding: math.div($grid-gutter-width, 4) 0;
    border: none;
    text-align: left;
    background: transparent;

    &:hover,
    &:focus,
    &:active {
      color: $link-hover-color;
    }
  }

  &__icon-wrapper {
    padding-left: math.div($grid-gutter-width, 4);
    padding-right: math.div($grid-gutter-width, 4);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: px-to-rem(2px);

    &--align-top {
      align-self: flex-start;
      padding-top: math.div($grid-gutter-width, 4);
    }
  }

  &__icon {
    width: math.div($grid-gutter-width, 2);
    aspect-ratio: 1 / 1;
  }

  &__filesize {
    width: fit-content;
    white-space: nowrap;
  }

  &__content {
    flex-grow: 1;
    padding-left: math.div($grid-gutter-width, 2);
    padding-right: math.div($grid-gutter-width, 2);
    word-break: break-all;

    &--no-break {
      word-break: initial;
    }
  }
}
