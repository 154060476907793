.loading {
  $loadingHeight: 100px;
  $loadingWidth: 200px;

  position: fixed;
  top: 50%;
  left: 50%;
  width: $loadingWidth;
  height: $loadingHeight;
  margin-top: $loadingHeight * -0.5;
  margin-left: $loadingWidth * -0.5;
  background: __get($theme-colors, 'primary');
  display: grid;
  place-content: center;
  color: #fff;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}
