iframe {
  border: none;
  width: 100%;

  &[src*='youtube'] {
    aspect-ratio: 16 / 9;

    .frame-imageorient-0 & {
      margin-bottom: $grid-gutter-width;

      @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-width * 2;
      }
    }

    .frame-imageorient-8 & {
      margin-top: $grid-gutter-width;

      @include media-breakpoint-up(lg) {
        margin-top: $grid-gutter-width * 2;
      }
    }

    .frame-imageorient-25 & {
      margin-top: $grid-gutter-width;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    .frame-imageorient-26 & {
      margin-bottom: $grid-gutter-width;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }
}
