@use 'sass:math';

.sticky-consent {
  @include media-breakpoint-up(md) {
    max-width: 300px;
  }

  font-size: px-to-rem(16px);
  line-height: 1.3;
}
