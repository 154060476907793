:root {
  --icon-w: 20px;
  --icon-h: 20px;
  --icon-margin: #{$grid-gutter-width * 0.25};
}

.icon {
  width: var(--icon-w);
  height: var(--icon-h);
  margin-right: var(--icon-margin);
  flex: 0 0 var(--icon-w);
  color: var(--ico-color);

  &-small {
    --icon-w: 20px;
    --icon-h: 20px;
    --icon-margin: #{$grid-gutter-width * 0.25};
  }

  &-space {
    margin-top: 6px;
  }

  &-rotate {
    rotate: 90deg;
  }

  &-rotate-45 {
    rotate: -45deg;
  }

  &-rotate-180 {
    rotate: 180deg;
  }
}
