@use 'sass:math';
@use 'sass:map';

:root {
  --custom-marker-display: block;
  --custom-marker-padding: #{px-to-rem(28px)};
  --custom-marker-w: #{math.div($grid-gutter-width, 8)};
  --custom-marker-h: #{math.div($grid-gutter-width, 8)};
  --custom-marker-bg: #{$primary};
  --custom-marker-position: #{math.div(px-to-rem(28px), 2)} auto auto #{math.div(px-to-rem(28px), -2)}; // inset
  --custom-marker-transform: translate(-50%, -50%);
}

.list-unstyled {
  --custom-marker-display: none;
}

main ul {
  padding-left: var(--custom-marker-padding);
  list-style: none;

  > li {
    position: relative;
    margin-bottom: var(--custom-marker-margin, 8px);

    &::before {
      display: var(--custom-marker-display, none);
      content: '';
      position: absolute;
      width: var(--custom-marker-w);
      height: var(--custom-marker-h);
      background: var(--custom-marker-bg);
      background-size: var(--custom-marker-bg-size, var(--custom-marker-h) var(--custom-marker-w));
      inset: var(--custom-marker-position);
      transform: var(--custom-marker-transform);
    }
  }

  &.epluselist {
    --custom-marker-w: 16px;
    --custom-marker-h: var(--custom-marker-w);
    --custom-marker-padding: calc(var(--custom-marker-w) * 2);
    --custom-marker-bg: #{url(map.get($plus-icon, 'icon'))};
    --custom-marker-bg-size: contain;
    --custom-marker-position: 0;
    --custom-marker-transform: translate(-200%, 40%);
    --custom-marker-margin: 8px;

    ul {
      margin-top: var(--custom-marker-margin);
    }
  }
}
