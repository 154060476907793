ul.list-items {
  li {
    a {
      p,
      small {
        color: $gray-700;
        strong {
          color: $brand-600;
        }
      }
    }
  }
}
