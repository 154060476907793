@use 'sass:math';

blockquote {
  border-left: 10px solid map-get($theme-colors, 'dark');
  padding: var(--blockquote-padding-inline);
  background: var(--blockquote-bg);

  a {
    font-style: normal;
  }
}

.blockquote {
  --blockquote-bg: transparent;
  --blockquote-padding-inline: 0;
  --blockquote-column-gap: 0;

  --blockquote-image: #{percentage(math.div(12, 12))};

  --blockquote-author: #{px-to-rem(16px)};
  --blockquote-author-width: #{percentage(math.div(9, 12))};

  --blockquote-cite: 100%;
  --blockquote-cite-order: 1;

  --blockquote-text-width: 100%;
  --blockquote-text: #{px-to-rem(30px)};
  --blockquote-text-small: #{px-to-rem(22px)};

  --blockquote-quote: 12px;
  --blockquote-quote-padding: var(--blockquote-quote);

  padding-inline: #{$grid-gutter-width * 0.5};

  .blockquote__quote + .blockquote__quote {
    margin-top: $grid-gutter-width * 2;
  }

  @include media-breakpoint-up(md) {
    --blockquote-column-gap: #{$grid-gutter-width * 1.25};

    --blockquote-image: #{percentage(math.div(5, 12))};

    --blockquote-author-width: #{percentage(math.div(6, 12))};

    --blockquote-cite: calc(100% - (var(--blockquote-image) + var(--blockquote-column-gap)));
    --blockquote-cite-order: 0;

    --blockquote-text-width: #{percentage(math.div(10, 12))};
    --blockquote-text: #{px-to-rem(32px)};
    --blockquote-text-small: #{px-to-rem(24px)};

    --blockquote-quote: 16px;
    --blockquote-quote-padding: 0;
  }

  @include media-breakpoint-up(xl) {
    --blockquote-column-gap: #{$grid-gutter-width * 1.75};

    --blockquote-text: #{px-to-rem(46px)};
    --blockquote-text-small: #{px-to-rem(32px)};
    --blockquote-quote: 20px;
  }

  @include media-breakpoint-up(ultrawide) {
    --blockquote-padding-inline: #{percentage(math.div(1, 12))};

    --blockquote-image: #{percentage(math.div(4, 12))};

    --blockquote-text: #{px-to-rem(50px)};
    --blockquote-text-small: #{px-to-rem(36px)};
  }

  &__quote {
    border: none;
    padding-inline: var(--blockquote-padding-inline);
    padding-block: 0;

    display: flex;
    flex-wrap: wrap;
    column-gap: var(--blockquote-column-gap);

    &-image {
      max-width: var(--blockquote-image);
      flex: 1 0 var(--blockquote-image);
    }

    &-text {
      flex: 1 0 var(--blockquote-text-width);
      max-width: var(--blockquote-text-width);

      &__small {
        --blockquote-text: var(--blockquote-text-small);
      }

      &:before {
        content: var(--quote-opening, '\275D');
        font-size: var(--blockquote-text);
        font-weight: bold;
        line-height: 1.3;
        color: $primary;
        display: flex;
        justify-content: center;
        flex: 1 0 var(--blockquote-quote);
        max-width: var(--blockquote-quote);
      }

      .bodytext {
        color: $primary;
        font-weight: bold;
        font-size: var(--blockquote-text);
        line-height: 1.3;

        &:after {
          content: var(--quote-closing, '\275E');
          font-size: var(--blockquote-text);
          line-height: 1.3;
          color: $primary;
        }
      }
    }

    &-cite {
      flex: 1 0 var(--blockquote-cite);
      max-width: var(--blockquote-cite);
      align-self: flex-end;
      order: var(--blockquote-cite-order);
      padding-left: var(--blockquote-quote-padding);
    }

    &-author {
      font-style: normal;
      font-size: var(--blockquote-author);
      flex: 0 var(--blockquote-author-width);
    }

    // no image
    &:not(:has(figure)) {
      --blockquote-cite-order: 1;
      --blockquote-cite: var(--blockquote-text-width);
      --blockquote-quote-padding: var(--blockquote-quote);

      .blockquote__quote-cite {
        padding-left: var(--blockquote-quote-padding);
        margin-inline: auto;
      }
    }
  }
}
