:root {
  @each $order, $sizes in $heading-sizes {
    --heading-#{$order}-color: #{map-get($theme-colors, 'copytext')};

    @each $breakpoint, $values in $sizes {
      @include media-breakpoint-up($breakpoint) {
        --heading-#{$order}-size: #{px-to-rem(__get($values, 'size'))};
        --heading-#{$order}-mb: #{px-to-rem(__get($values, 'margin'))};
      }
    }
  }
}

@each $order, $sizes in $heading-sizes {
  h#{$order},
  .h#{$order} {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    color: var(--heading-#{$order}-color);

    @each $breakpoint, $values in $sizes {
      @include media-breakpoint-up($breakpoint) {
        font-size: var(--heading-#{$order}-size);
        margin-bottom: var(--heading-#{$order}-mb);
      }
    }
  }
}

// special heading settings, when a heading follows a paragraph - eg in RTE context
p + h3 {
  margin-top: px-to-rem(16px);

  @include media-breakpoint-up(xl) {
    margin-top: px-to-rem(24px);
  }
}
