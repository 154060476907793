@use 'sass:math';

.cta-banner {
  --heading-3-color: #{$white};

  display: grid;
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  overflow: hidden;

  &__image,
  &__overlay {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  &__image {
    @include media-breakpoint-down(sm) {
      transform: scale(1.5);
      filter: blur(20px);
    }
  }

  &__overlay {
    z-index: 1;
  }

  &__text {
    flex: 0 0 calc(100% - #{$grid-gutter-width});

    @include media-breakpoint-up(md) {
      flex: 0 0 percentage(math.div(6, 12));
    }

    @include media-breakpoint-up(xl) {
      flex: 0 0 percentage(math.div(5, 12));
    }

    .frame-layout-0:not(.frame-type-eeproducts_applications) & {
      --heading-3-color: #{$body-color};
      background: rgba(map-get($theme-colors, 'white'), 0.9);
    }

    .frame-layout-1 & {
      background: map-get($theme-colors, 'primary');
    }
  }
}
