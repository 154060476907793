:root {
  --container-max-width: 100%;

  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint) {
      --container-max-width: #{$container-max-width};
    }
  }

  --teaser-opacity: 0;
  --teaser-translateY: 50px;
  --teaser-img-translateY: 100px;

  --blockquote-bg: #{map-get($theme-colors, 'light')};
  --blockquote-padding-inline: #{$grid-gutter-width * 0.5};

  // temporary overridings for headings on the startpage
  .rootpage {
    --heading-2-color: #{map-get($theme-colors, 'copytext')};
  }

  --quote-opening: '“ ';
  --quote-closing: ' ”';
}

// override quotation marks for blockquotes for german and french
html[lang='de']:root {
  --quote-opening: '„ ';
  --quote-closing: ' ”';
}

html[lang='fr']:root {
  --quote-opening: '« ';
  --quote-closing: ' »';
}
