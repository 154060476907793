@use 'sass:math';

.dealer-list {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.dealer-header {
  background: transparent !important;
  padding-bottom: math.div($grid-gutter-width, 2) !important;
  border-bottom: 1px solid $gray-200;
  line-height: $line-height-base;

  > * {
    color: $primary;
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    text-align: left;
    gap: math.div($grid-gutter-width, 2);
    min-height: $grid-gutter-width;

    &[data-sort]:after {
      content: '';
      display: block;
      opacity: 0.5;
      background-image: $datatable-sort-available-icon;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: math.div($grid-gutter-width, 2);
      aspect-ratio: 1 / 2;
      flex-shrink: 0;
    }

    &.sort-asc::after {
      background-image: $datatable-sort-ascending-icon;
      opacity: 1;
    }

    &.sort-desc::after {
      background-image: $datatable-sort-descending-icon;
      opacity: 1;
    }
  }
}

.dealer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: math.div($grid-gutter-width, 2);
  padding: $grid-gutter-width math.div($grid-gutter-width, 2);
  line-height: $line-height-lg;

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: auto auto auto;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 3fr 2fr 4fr 4fr;
    grid-template-rows: auto;
  }

  &:nth-child(odd) {
    background: $gray-100;
  }

  &__address-area {
    display: contents;
    line-height: inherit;
  }

  &__title {
    color: $primary;
    margin: 0;
    line-height: inherit;

    @include media-breakpoint-up(md) {
      grid-row: 1;
      grid-column: 1;
    }

    a {
      text-decoration: none;
    }
  }

  &__country {
    margin: 0;

    @include media-breakpoint-up(md) {
      grid-row: 2;
      grid-column: 1;
    }

    @include media-breakpoint-up(xl) {
      grid-row: 1;
      grid-column: 2;
    }
  }

  &__address {
    margin: 0;

    @include media-breakpoint-up(md) {
      grid-row: 3;
      grid-column: 1;
    }

    @include media-breakpoint-up(xl) {
      grid-row: 1;
      grid-column: 3;
    }
  }

  &__contact-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    @include media-breakpoint-up(md) {
      grid-row: 1 / 4;
      grid-column: 2;
    }

    @include media-breakpoint-up(xl) {
      grid-row: 1;
      grid-column: 4;
    }
  }

  &__contact {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: math.div($grid-gutter-width, 2);
    margin: 0;
    color: $body-color;
    text-decoration: underline;

    svg {
      display: block;
      width: math.div($grid-gutter-width, 2);
      height: math.div($grid-gutter-width, 2);
      flex-shrink: 0;
      flex-grow: 0;
      color: $body-color !important;
    }
  }
}
