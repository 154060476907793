@use 'sass:math';

.sticky-contact {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: math.div($grid-gutter-width, 2);

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $grid-gutter-width;
  }

  &__hours {
    display: block;
    font-size: 0.8em;
    color: $gray-600;
  }

  &__phone {
    color: $body-color;
  }
}
