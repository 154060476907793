@use 'sass:math';

.quotes-slider {
  --controls-width: 100%;
  --track-row-gap: #{$grid-gutter-width};

  @include media-breakpoint-up(md) {
    --controls-width: #{percentage(math.div(10, 12))};
  }

  @include media-breakpoint-up(xl) {
    --track-row-gap: #{$grid-gutter-width * 1.25};
  }

  @include media-breakpoint-up(ultrawide) {
    --controls-width: #{percentage(math.div(8, 12))};
  }

  > .splide {
    row-gap: var(--track-row-gap);
  }

  &__controls-inner {
    max-width: var(--controls-width);
  }
}
