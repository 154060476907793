.layover {
  &__map {
    position: relative;
    width: fit-content;
    margin: 0 auto;
  }

  &__item {
    position: absolute;
    background: $primary;
    border-radius: 50%;
    width: $grid-gutter-width;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $box-shadow;
    margin-left: -$grid-gutter-width * 0.5;
    margin-top: -$grid-gutter-width * 0.5;
  }

  &__icon {
    display: block;
    width: 100%;
    aspect-ratio: 1 / 1;

    path {
      fill: $white;
    }
  }
}
