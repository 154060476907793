:root {
  --one-column-grid-rows: 1fr;
  --one-column-grid-cols: 1fr;
  --one-column-grid-layer-1-row: 1;
  --one-column-grid-layer-1-col: 1;
  --one-column-grid-layer-2-row: 1;
  --one-column-grid-layer-2-col: 1;
  --one-column-grid-gap: 0;
}

.one-column-grid {
  display: grid;
  grid-template-rows: var(--one-column-grid-rows);
  grid-template-columns: var(--one-column-grid-cols);
  gap: var(--one-column-grid-gap);

  &__layer {
    &-1 {
      z-index: 1;

      grid-row: var(--one-column-grid-layer-1-row);
      grid-column: var(--one-column-grid-layer-1-col);
    }

    &-2 {
      grid-row: var(--one-column-grid-layer-2-row);
      grid-column: var(--one-column-grid-layer-2-col);
    }
  }
}
