figcaption {
  font-size: px-to-rem(14px);
  text-align: left;
  padding: ($grid-gutter-width * 0.25) ($grid-gutter-width * 0.5) ($grid-gutter-width * 0.25) 0;

  .splide__slide & {
    padding: ($grid-gutter-width * 0.125) ($grid-gutter-width * 0.25);
    position: absolute;
    inset: auto 0 0 0;
    overflow: hidden;
    background: rgba(__get($theme-colors, 'white'), 0.8);
    backdrop-filter: blur(2px);
  }
}
