@use 'sass:math';

// load all variables
@import 'breakpoints';
@import 'container';
@import 'aspect-ratios';

// Load bootstrap functions & mixins

//@import "../../../node_modules/bootstrap/scss/mixins";

// Colors
$white: #ffffff;
$gray-100: #f9fafa;
$gray-200: #eeeeee;
$gray-300: #e2e2e2;
$gray-400: #d4d4d4;
$gray-500: #b5b5b5;
$gray-600: #757575;
$gray-700: #494a49;
$gray-800: #3a3a3a;
$gray-900: #232323;

$brand-100: #f4f8f0;
$brand-200: #d6e7c7;
$brand-300: #b6d6a0;
$brand-400: #95c67a;
$brand-500: #72b554;
$brand-600: #48a42c;
$brand-700: #3a8323;
$brand-800: #2b621a;
$brand-900: #1d4212;

$orange-100: #ffe3c8;
$orange-200: #fec691;
$orange-300: #feaa5b;
$orange-400: #fe8d24;
$orange-500: #ea7101;
$orange-600: #bb5b01;
$orange-700: #753901;
$orange-800: #5d2d00;
$orange-900: #2f1700;

$red: #dc3545;
$dark: $gray-700;
$light: $gray-100;
$info: #b6d4fe;

$primary: $brand-600;
$primary-alt: #52ae32;
$secondary: $gray-600;
$success: $brand-400;
$danger: $red;
$warning: #ffecb5;

$alert-bg-scale: 0;
$alert-border-radius: px-to-rem(2px);

$color-contrast-dark: $dark;
$color-contrast-dark: $light;

$body-bg: $white;
$body-color: $dark;

$link-color: $brand-700;
$link-hover-color: $brand-800;

$theme-colors: (
  primary: $primary,
  primary-100: $brand-100,
  secondary: $secondary,
  success: $success,
  warning: $warning,
  danger: $danger,
  'white': $white,
  light: $gray-100,
  medium: $gray-500,
  dark: $gray-700,
  'copytext': $dark,
  input: $gray-400,
  link: $link-color,
  info: $info,
  'logIn': $orange-600,
  teaser: $gray-900,
);

$gradient: linear-gradient(45deg, $gray-200 14.64%, $gray-100 85.36%);
$gradient2: linear-gradient(180deg, $gray-100 0%, $white 100%);

$box-shadow: var(--box-shadow-x, 0) var(--box-shadow-y, 1px) var(--box-shadow-blur, 4px) var(--box-shadow-spread, 0)
  var(--box-shadow-color, #{rgba($body-color, var(--box-shadow-color-opacity, 0.25))});
$box-shadow-xl: 0 px-to-rem(4px) px-to-rem(20px) rgba(#000, 0.25);

// Bootstrap options
$enable-rfs: false;
$enable-negative-margins: true;

// Grid settings
$grid-breakpoints: map-loop($grid-breakpoints, 'px-to-rem', '$value');
$container-max-widths: map-loop($container-max-widths, 'px-to-rem', '$value');
$grid-gutter-width: 2rem;
$container-padding-x: math.div($grid-gutter-width, 2);

// Headings & Fonts
$font-path: '../Webfonts/helveticanow';
$font-family-sans-serif: 'Helvetica Now Display', 'Helvetica Neue', Helvetica, Verdana, Arial, sans-serif;
$font-size-base: px-to-rem(18px);
$line-height-base: 1.5;
$line-height-lg: 1.9;

$font-weight-normal: 400;
$font-weight-bold: 700;

$headings-font-family: 'Helvetica Now Display', 'Helvetica Neue', Helvetica, Verdana, Arial, sans-serif;
$headings-font-weight: $font-weight-bold;
$headings-margin-bottom: $grid-gutter-width;

$heading-sizes: (
  1: (
    xs: (
      size: 36px,
      margin: 24px,
    ),
    xl: (
      size: 64px,
      margin: 28px,
    ),
  ),
  2: (
    xs: (
      size: 28px,
      margin: 16px,
    ),
    xl: (
      size: 48px,
      margin: 24px,
    ),
  ),
  3: (
    xs: (
      size: 24px,
      margin: 16px,
    ),
    xl: (
      size: 36px,
      margin: 24px,
    ),
  ),
);

$font-sizes: (
  1: px-to-rem(__get($heading-sizes, 1 'xl' 'size')),
  2: px-to-rem(__get($heading-sizes, 2 'xl' 'size')),
  3: px-to-rem(__get($heading-sizes, 3 'xl' 'size')),
  3xs: px-to-rem(__get($heading-sizes, 3 'xs' 'size')),
  copytext: $font-size-base,
);

$plus-icon: (
  icon:
    "data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.2019 12.2976V0H12.7981V12.2976H0.499481V22.7014H12.7981V35H23.2019V22.7014H35.5005V12.2976H23.2019Z' fill='%2348A42C'/%3E%3C/svg%3E%0A",
  icon-white:
    "data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.2019 12.2976V0H12.7981V12.2976H0.499481V22.7014H12.7981V35H23.2019V22.7014H35.5005V12.2976H23.2019Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A",
  sizes: (
    1: (
      xs: (
        w: 20px,
        h: 20px,
        top: 11px,
        left: 18px,
        margin: 48px,
      ),
      xl: (
        w: 35px,
        h: 35px,
        top: 18.5px,
        left: 31.5px,
        margin: 56px,
      ),
    ),
    2: (
      xs: (
        w: 16px,
        h: 16px,
        top: 10px,
        left: 14px,
        margin: 32px,
      ),
      xl: (
        w: 24px,
        h: 24px,
        top: 13px,
        left: 20px,
        margin: 56px,
      ),
    ),
    3: (
      xs: (
        w: 14px,
        h: 14px,
        top: 9px,
        left: 12px,
        margin: 32px,
      ),
      xl: (
        w: 18px,
        h: 18px,
        top: 10px,
        left: 15px,
        margin: 40px,
      ),
    ),
  ),
);

// Empty map to generate no display-classes
$display-font-sizes: ();

// spacers for margin and padding spaces
// use classnames like px-fg or px-lg-fg
// for full gutter padding on x-axis or
// full gutter padding lg or bigger on x-axis
$spacers: (
  0: 0,
  vsg: $grid-gutter-width * 0.125,
  sg: $grid-gutter-width * 0.25,
  hg: $grid-gutter-width * 0.5,
  qp: $grid-gutter-width * 0.75,
  fg: $grid-gutter-width,
  1sg: $grid-gutter-width * 1.25,
  1h: $grid-gutter-width * 1.5,
  1hq: $grid-gutter-width * 1.75,
  dg: $grid-gutter-width * 2,
  2sg: $grid-gutter-width * 2.25,
  2h: $grid-gutter-width * 2.5,
  tg: $grid-gutter-width * 3,
  qg: $grid-gutter-width * 4,
);

// Buttons
$btn-padding-y: px-to-rem(11px);
$btn-padding-x: $grid-gutter-width * 0.75;
$btn-font-family: $font-family-sans-serif;
$btn-font-size: $font-size-base;
$btn-font-weight: $font-weight-bold;
$btn-line-height: $line-height-base;
$btn-border-width: 1px;
$btn-border-radius: 2px;
$btn-focus-width: 0;
$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;

$btn-padding-y-lg: px-to-rem(20px);
$btn-padding-x-lg: $grid-gutter-width;
$btn-font-size-lg: $btn-font-size;
$btn-border-radius-lg: 4px;

$z-index-nav-overlay: 1000;
$z-index-page-header: 1001;

// input fields
$input-border-radius: 2px;
$form-select-border-radius-lg: $input-border-radius;
$input-btn-padding-y: px-to-rem(11.5px);
$input-btn-padding-x: $grid-gutter-width * 0.5;

// label
$form-label-margin-bottom: px-to-rem(4px);
$form-label-font-size: px-to-rem(14px);

// slider
$slider-description-font-size: px-to-rem(14px);

// legend
$legend-margin-bottom: 0;
$legend-font-size: inherit;
$legend-font-weight: $font-weight-bold;

// accordion
$accordion-padding-y: $grid-gutter-width * 0.25;
$accordion-padding-x: $grid-gutter-width * 0.5;
$accordion-bg: $gray-100;
$accordion-border-width: 0;
$accordion-border-radius: 0;
$accordion-inner-border-radius: 0;
$accordion-button-active-bg: $accordion-bg;
$accordion-button-active-color: $body-color;

// badges
$badge-font-weight: $font-weight-normal;
$badge-font-size: px-to-rem(11px);
$badge-sm-padding-x: math.div($grid-gutter-width, 8);
$badge-sm-padding-y: math.div($grid-gutter-width, 16);

// tables
$table-border-factor: 0;
$table-border-width: 0;

// cards
$card-border-width: 0;
$card-border-radius: 4px;
$card-bg: $gray-100;

// forms
$form-feedback-invalid-color: $danger;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");
$form-label-font-size: px-to-rem(14px);
$form-feedback-font-size: px-to-rem(14px);
$form-validation-states: (
  'invalid': (
    'color': $form-feedback-invalid-color,
    'icon': $form-feedback-icon-invalid,
  ),
);

// datatable
$datatable-sort-available-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z'/%3E%3C/svg%3E");
$datatable-sort-ascending-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3E%3C/svg%3E");
$datatable-sort-descending-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z'/%3E%3C/svg%3E");

// tables
$table-striped-bg: $gray-100;
$table-striped-order: even;

// tooltips
$tooltip-bg: $white;
$tooltip-padding-y: math.div($grid-gutter-width, 4);
$tooltip-padding-x: math.div($grid-gutter-width, 4);
$tooltip-color: $body-color;
$tooltip-border-radius: px-to-rem(2px);
$tooltip-opacity: 1;
$tooltip-max-width: $grid-gutter-width * 7.5;
