@mixin button-custom-variant(
  $background,
  $border,
  $color: color-contrast($background),
  $hover-border:
    if(
      $color == $color-contrast-light,
      shade-color($border, $btn-hover-border-shade-amount),
      tint-color($border, $btn-hover-border-tint-amount)
    )
) {
  &:focus-visible {
    color: $color;
    border-color: $border;
    background: $background;
  }

  @include button-focus-variant($hover-border);
}

@mixin button-custom-outline-variant($color, $border: $color) {
  &:focus-visible {
    color: $color;
    border-color: $border;
  }

  @include button-focus-variant($border);
}

@mixin button-focus-variant($border-hover-color) {
  position: relative;

  &::after {
    position: absolute;
    display: block;
    content: '';
    left: -$btn-border-width;
    top: -$btn-border-width;
    bottom: -$btn-border-width;
    width: px-to-rem(4px);
    background: $border-hover-color;
    border-bottom-left-radius: inherit;
    border-top-left-radius: inherit;
    opacity: 0;
    pointer-events: none;

    @include motion-safe {
      transition: $transition-fade;
    }
  }

  &:focus-visible {
    outline: none;

    &::after {
      opacity: 1;
    }
  }
}
