@use 'sass:math';

.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: math.div($grid-gutter-width, 4);
  font-size: px-to-rem(14px);

  &__item {
    display: none;
    align-items: center;
    justify-content: flex-start;
    gap: math.div($grid-gutter-width, 4);

    &--first {
      display: flex;
    }

    &--last {
      color: $primary;
    }

    @include media-breakpoint-up(xl) {
      display: flex;
    }
  }

  &__arrow {
    display: block;
    width: 0.75em;
    aspect-ratio: 1 / 1;

    @include media-breakpoint-up(xl) {
      transform-origin: center center;
      transform: rotateY(180deg);
    }
  }

  &__link {
    color: inherit;
    text-decoration: none;
    line-height: 1;
    padding: math.div($grid-gutter-width, 4) 0;

    &:hover,
    &:active,
    &:focus {
      color: inherit;
    }
  }
}
