@use 'sass:math';

.segment-detail {
  &__header {
    --heading-2-mb: #{$grid-gutter-width * 0.5};

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      'title'
      'measurements'
      'content';
    column-gap: $grid-gutter-width;

    @include media-breakpoint-up(xl) {
      grid-template-columns: 9fr 300px;
      grid-template-rows: auto auto;
      grid-template-areas:
        'title title'
        'content -';
    }

    &-title {
      grid-area: title;
    }

    &-content {
      grid-area: content;
      margin-bottom: $grid-gutter-width;

      .epluselist {
        @include media-breakpoint-up(md) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: $grid-gutter-width * 1.25;
        }

        li {
          font-weight: 700;
        }
      }
    }
  }

  &__clients {
    --client-item-width: #{percentage(math.div(12, 12))};
    --custom-marker-margin: 0;

    @include media-breakpoint-up(sm) {
      --client-item-width: #{percentage(math.div(6, 12))};
    }

    @include media-breakpoint-up(md) {
      --client-item-width: 20%;
    }

    &-item {
      display: grid;
      place-content: center;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: calc(var(--client-item-width) - #{$grid-gutter-width * 0.5});
    }

    &-image {
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 140px;
    }
  }

  &__scrubber {
    .scrubber {
      --divider-width: 3px;
      --divider-color: #{$primary};
      --default-handle-color: #{$primary};
    }

    &-caption {
      opacity: var(--scrubber-opacity, 100%);
      transition: opacity 0.2s ease-in-out;
    }
  }
}
