@use 'sass:math';
@use 'sass:map';

.frame-type-kk_contentelements_form_image,
.frame-type-theme_whitepaperform {
  --grid-columns: repeat(2, auto);
  --grid-rows: repeat(3, auto);
  --col-gap: 0;
  --row-gap: #{$grid-gutter-width};

  --heading-col: 1/3;
  --heading-row: 1;

  --form-col: 1/3;
  --form-row: 3;

  --contact-col: 1/3;
  --contact-row: 2;

  @include media-breakpoint-up(lg) {
    --grid-columns: #{calc(percentage(math.div(7, 12)) - $grid-gutter-width * 0.25)} auto;
    --grid-rows: repeat(2, auto);
    --col-gap: #{$grid-gutter-width};

    --heading-col: 1;

    --form-col: 1;
    --form-row: 2;

    --contact-col: 2;
    --contact-row: 2;
  }

  @include media-breakpoint-up(xl) {
    --col-gap: #{$grid-gutter-width * 4};
  }

  .form-image {
    &__wrapper {
      grid-template-columns: var(--grid-columns);
      grid-template-rows: var(--grid-rows);
      column-gap: var(--col-gap);
      row-gap: var(--row-gap);
    }

    &__heading {
      grid-column: var(--heading-col);
      grid-row: var(--heading-row);
    }

    &__form {
      grid-column: var(--form-col);
      grid-row: var(--form-row);

      &-dynamics {
        &:has(> .consent) {
          place-content: center;
          position: relative;

          --form-bg: url(../Images/form-bg-sm.jpg);
          --form-height: 600px;

          @include media-breakpoint-up(sm) {
            --form-bg: url(../Images/form-bg-sm.jpg);
            --form-height: 895px;
          }

          @include media-breakpoint-up(md) {
            --form-bg: url(../Images/form-bg-md.jpg);
            --form-height: 895px;
          }

          @include media-breakpoint-up(lg) {
            --form-bg: url(../Images/form-bg-lg.jpg);
            --form-height: 619px;
          }

          @include media-breakpoint-up(xl) {
            --form-bg: url(../Images/form-bg-xl.jpg);
          }

          @include media-breakpoint-up(xxl) {
            --form-bg: url(../Images/form-bg-xxl.jpg);
          }

          @include media-breakpoint-up(uw) {
            --form-bg: url(../Images/form-bg-uw.jpg);
            --form-height: 561px;
          }

          .consent {
            padding: $grid-gutter-width * 0.75;
            background: $white;
            box-shadow: 0 2.8px 2.2px rgba($gray-900, 0.034), 0 6.7px 5.3px rgba($gray-900, 0.048),
              0 12.5px 10px rgba($gray-900, 0.06), 0 22.3px 17.9px rgba($gray-900, 0.072),
              0 41.8px 33.4px rgba($gray-900, 0.086), 0 100px 80px rgba($gray-900, 0.12);
            z-index: 1;
          }

          &:after {
            content: '';
            background: var(--form-bg) no-repeat center;
            background-size: cover;
            filter: blur(4px);
            opacity: 0.85;
            position: absolute;
            inset: 0;
            z-index: 0;
          }

          min-height: var(--form-height);
        }
      }
    }

    &__contact {
      --border-radius: 50%;
      --align-items: center;

      grid-column: var(--contact-col);
      grid-row: var(--contact-row);

      @include media-breakpoint-up(lg) {
        --border-radius: 0;
        --align-items: start;
      }

      img {
        border-radius: var(--border-radius);
      }

      figure {
        align-items: var(--align-items);
      }
    }
  }
}
