/* --- Helvetica Now webfont inclusion START --- */

// bold
@font-face {
  font-display: swap;
  font-family: 'Helvetica Now Display';
  src: url('#{$font-path}/HelveticaNowProDisplayBold/font.woff2') format('woff2'),
    url('#{$font-path}/HelveticaNowProDisplayBold/font.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
// italig regular
@font-face {
  font-display: swap;
  font-family: 'Helvetica Now Display';
  src: url('#{$font-path}/HelveticaNowProDisplayItalic/font.woff2') format('woff2'),
    url('#{$font-path}/HelveticaNowProDisplayItalic/font.woff') format('woff');
  font-style: italic;
  font-weight: 400;
}
// regular
@font-face {
  font-display: swap;
  font-family: 'Helvetica Now Display';
  src: url('#{$font-path}/HelveticaNowProDisplayRegular/font.woff2') format('woff2'),
    url('#{$font-path}/HelveticaNowProDisplayRegular/font.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

/* --- Helvetica Now webfont inclusion END --- */
