.accordion {
  &-button {
    &:before {
      content: '';
      inset: 0;
      z-index: 1;
      position: absolute;
    }

    &:after {
      inset: ($grid-gutter-width * 0.25) ($grid-gutter-width * 0.5) 0 0;
    }
  }
}
