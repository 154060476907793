@use 'sass:string';
@use 'sass:math';

.hero {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-gap: 0;
  overflow: hidden;

  &-slider,
  &-background {
    grid-column: string.unquote('1 / 1');
    grid-row: 1 / 1;
  }

  &-slider {
    z-index: 1;

    & > .splide {
      background: rgba($white, 0.9);

      .splide__track {
        grid-row: 1;

        @include media-breakpoint-up(md) {
          grid-row: 1 / span 2;
        }
      }
    }

    &__controls {
      grid-column: 1 / span 2;

      @include media-breakpoint-up(md) {
        grid-column: 2;
      }

      grid-row: 2;

      z-index: 1;
    }
  }

  &-background {
    filter: blur(20px);
    transform: scale(1.5);

    img {
      height: 100%;
    }

    /**
      special breakpoint only for this hero element
      that the blur effect is removed in an inbetween step
      related: https://support.sup7.at/issues/15721
     */
    @media (min-width: px-to-rem(375px)) {
      filter: none;
      transform: none;

      img {
        height: auto;
      }
    }
  }
}
