@use 'sass:math';

.teaser-cta {
  --teaser-gutter: #{$grid-gutter-width * 0.5};

  @include media-breakpoint-up(md) {
    @include motion-safe {
      opacity: var(--teaser-opacity);
      transform: translateY(var(--teaser-translateY));
      transition: all 0.5s ease-in-out;
    }
  }

  &__wrap {
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns:
      minmax(var(--teaser-gutter), 1fr) calc(var(--container-max-width) - calc(var(--teaser-gutter) * 2))
      minmax(var(--teaser-gutter), 1fr);

    @include media-breakpoint-up(md) {
      grid-template-columns: minmax(var(--teaser-gutter), 1fr) var(--container-max-width) minmax(
          var(--teaser-gutter),
          1fr
        );
    }

    &:before {
      content: '';
      grid-row: 2;
      grid-column: 1 / span 3;
      background: map-get($theme-colors, 'primary-100');
    }
  }

  &__text {
    grid-row: 2;
    grid-column: 2;
    align-self: center;

    text-wrap: balance;
    hyphens: auto;
    z-index: 1;

    h2 {
      @include media-breakpoint-up(md) {
        max-width: percentage(math.div(8, 12));
      }
    }

    ul + .btn {
      margin-top: $grid-gutter-width * 0.25;

      @include media-breakpoint-up(xl) {
        margin-top: $grid-gutter-width * 0.5;
      }
    }
  }

  &__image {
    grid-row: 1;
    grid-column: 1 / span 3;
    width: 100%;

    @include media-breakpoint-up(md) {
      @include motion-safe {
        transform: translateY(var(--teaser-img-translateY));
        transition: all 0.8s ease-in-out;
      }
    }

    @include media-breakpoint-up(md) {
      grid-row: 1 / span 3;
      grid-column: 2;
      justify-self: end;
      align-self: center;
      width: auto;
    }
  }
}
